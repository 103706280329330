import { StorageService } from './storage.service';

const tokenKey = 'token';

export class TokenService {
    private static instance: TokenService;
    private token: string;

    static getInstance() {
        return this.instance || (this.instance = new this());
    }

    public get() {

        if (!this.token) {
            try {

                this.loadToken();
            } catch (e) {
                return null;
            }
        }
        return this.token || null;
    }

    public getBearerToken() {
      return TokenService.getInstance().get() ? 'Bearer ' + TokenService.getInstance().get() : null;
    }

    private loadToken() {
        const token = StorageService.get(tokenKey);
        if (token) {
            this.token = atob(token);
        }
    }

    public set(token: string) {
        this.token = token;
        StorageService.set(tokenKey, btoa(token));
    }

    public clearToken() {
      this.token = null;
      StorageService.remove(tokenKey);
    }
}
