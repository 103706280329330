import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuApi } from '../../../../api-module/api/menu.api';
import { MenuItem } from '../../../../api-module/model/menu-item';
import { Profile } from '../../../../api-module/model/profile';
import { AuthService } from '../../../../../services/auth.service';
import { MenuInvalidationService } from '../../../../../services/menu-invalidation.service';
import { Subscription } from 'rxjs';
import { CONTACT } from '../../../../../constants/contact';
import { PatchApi } from '../../../../api-module/api/patch.api';
import { LanguageStateService } from '../../../../../services/language-state.service';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component implements OnInit, OnDestroy {

  menu: MenuItem[];
  @Input() loggedUser: Profile;
  contact = CONTACT;
  toggle = false;

  private subs: {[key: string]: Subscription}[] = [];
  private lang: string;

  constructor(
    private menuApi: MenuApi,
    private patchApi: PatchApi,
    private authService: AuthService,
    private menuInvalidation: MenuInvalidationService,
    private languageService: LanguageStateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loggedUser = AuthService.userProfile;
    this.lang = this.languageService.getLang() || environment.defaultLanguage;
    this.subs['user'] = this.authService.user.subscribe(u => {
      this.loggedUser = u;
    });
    this.subs['menu'] = this.menuInvalidation.menuReload.subscribe(() => {
      this.menu = null;
      this.loadMenu(this.lang);
    });
    this.subs['translate'] = this.languageService.languageState.subscribe((lang) => {
      this.menu = null;
      this.lang = lang;
      this.setProfilePreferredLanguage(lang);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subs).forEach(key => this.subs[key].unsubscribe());
  }

  toggleMenu() {
    this.toggle = !this.toggle;
    const collState = this.toggle ? 'show' : 'hide';

    $('.header-nav').collapse(collState);
  }

  navigateTo(uri) {
    this.router.navigate([uri]).then(() => {
      this.toggle = false;
      $('.header-nav').collapse('hide');
    });
    return false;
  }

  private loadMenu(lang) {
    if (this.menu) {
      return;
    }

    this.menuApi.fetchMenu('frontend_menu', lang).subscribe(r => {
      this.menu = r.content.items;
    });
  }

  private setProfilePreferredLanguage(code) {
    if (!this.loggedUser) {
      this.loadMenu(code);
      return;
    }
    this.patchApi.patchValue(
      'registration',
      this.loggedUser.id,
      'preferredLanguage',
      code
    ).subscribe(r => {
      if (r.code === 200) {
        this.loggedUser.preferred_language = code;
        AuthService.userProfile.preferred_language = code;
        this.loadMenu(code);
      }
    });
  }
}
