<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <div class="registration">

        <!-- TITLE START -->
        <div class="section-head">
          <div class="mt-separator-outer separator-center">
            <div class="mt-separator">
              <h2 class="text-uppercase sep-line-one" [innerHtml]="'REG.page_title' | translate"></h2>
            </div>
          </div>
        </div>
        <!-- TITLE END -->

        <div class="mt-post-text text-center">
          <p [innerHtml]="'REG.p1' | translate"></p>
          <p [innerHtml]="'REG.p2' | translate"></p>
        </div>

        <div class="mt-post-text text-center">
          <a href="https://www.coffee-taxi.sk" class="btn btn-primary btn-lg">www.coffee-taxi.sk</a>
        </div>
      </div>
    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
