import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningReportListResponse } from '../model/response/earning-report-list.response';

@Injectable()
export class AdminUberEarningsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchEarnings(page, limit, filter = null) {
    return this.http.get<EarningReportListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/v2/uber-earnings',
        page,
        limit,
        [{totalGrossEarnings: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveRecord(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning/' + id
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning/' + id
      ,{headers: this.getAuthHeaders()}
    );
  }
}
