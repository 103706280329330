import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: '[form-checkbox]',
  templateUrl: './checkbox.component.html',
  styleUrls: [
    './checkbox.component.scss',
    '../../styles/label.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit {
  @HostBinding('attr.id') externalId = '';

  @ViewChild('input') inputRef: ElementRef;

  @Input() label = '';
  @Input() required = false;
  @Input() description = '';
  @Input() errors: {[key: string]: boolean} | null;
  @Input() messages: {[key: string]: string} = {};
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';
  @Input('disabled') _DISABLED = false;

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  _ID = '';
  _VALUE = false;

  private defaultMessages = {
    required: 'Field is required',
    minlength: 'Field does not catch minimal length',
    maxlength: 'Field is over max length',
    pattern: 'Field does not match the validation pattern',
  };

  @Input()
  set value(val) {
    this._VALUE = Boolean(val);
    this.onChange(val);
    this.onTouched();
  }

  get value() {
    return this._VALUE;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.messages = Object.assign(this.defaultMessages, this.messages);
    this.renderer.addClass(this.elementRef.nativeElement, 'checkbox');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._DISABLED = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this._VALUE = obj;
    }
  }

  doChange($event) {
    this.onChange($event.target.checked);
    this.onTouched();
  }

  toggle() {
    const newValue = !this.inputRef.nativeElement.checked;
    if(newValue) {
      this.inputRef.nativeElement.setAttribute('checked', 'checked');
    } else {
      this.inputRef.nativeElement.removeAttribute('checked');
    }
    this.onChange(newValue);
    this.onTouched();
  }

  getErrorByKey(key): string {
    if (this.messages.hasOwnProperty(key)) {
      return this.messages[key];
    }

    return 'Something is wrong with this field';
  }
}
