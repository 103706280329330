import { SelectItemInterface } from '../modules/forms/models/select-item.interface';

export const PAGE_LANGUAGES = {
  SK: {
    code: 'sk',
    abbr: 'SK',
    name: 'Slovensky',
    flag: 'sk'
  },
  EN: {
    code: 'en',
    abbr: 'EN',
    name: 'English',
    flag: 'gb'
  },
  RU: {
    code: 'ru',
    abbr: 'RU',
    name: 'Русский',
    flag: 'ru'
  },
};

export const LANGUAGE_OPTIONS: SelectItemInterface[] = Object.keys(PAGE_LANGUAGES).map(k => {
  return {label: PAGE_LANGUAGES[k].name, value: PAGE_LANGUAGES[k].code};
});
