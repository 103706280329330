<div class="alerts-wrapper" *ngIf="shownMessages.length">
  <div class="alert-item" *ngFor="let message of shownMessages">
    <div [className]="'alert alert-' + message.type" [ngClass]="{'alert-dismissible': message.closer}" [ngStyle]="childStyle">
      <button
        type="button"
        class="close"
        aria-label="Close"
        *ngIf="message.closer"
        (click)="remove(message.id)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {{ message.message }}
    </div>
  </div>
</div>
