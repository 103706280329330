import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LanguageStateService } from '../../../services/language-state.service';
import { LeadCommentsResponse } from '../model/response/lead-comments.response';
import { LeadCommentResponse } from '../model/response/lead-comment.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class AdminLeadCommentsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public fetchComments(filter, page, limit) {
    return this.http.get<LeadCommentsResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/lead-comments',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchComment(id) {
    return this.http.get<LeadCommentResponse>(
      this.baseUrl + '/api/cf/administration/lead-comments/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public save(commentRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/lead-comments',
      commentRequest,
      {headers: this.getAuthHeaders()}
    );
  }

  public update(id, commentRequest) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/lead-comments/' + id,
      commentRequest,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/lead-comments/' + id,
      {headers: this.getAuthHeaders()}
    );
  }
}
