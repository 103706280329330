import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { CalculatorBlockResponseInterface } from '../model/response/calculator-block-response.interface';

@Injectable()
export class CalculatorApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchCalculator(key) {
    return this.http.get<CalculatorBlockResponseInterface>(this.baseUrl + '/api/calculator/' + key);
  }
}
