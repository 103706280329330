import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItemInterface } from '../../modules/forms/models/select-item.interface';
import { CalculatorApi } from '../../modules/api-module/api/calculator.api';
import { Calculator } from '../../modules/api-module/model/calculator';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-calculator-courier-page',
  templateUrl: './calculator-courier.component.html',
  styleUrls: [
    './calculator-courier.component.scss'
  ]
})
export class CalculatorCourierComponent implements OnInit, OnDestroy {

  private days = [];
  private hours = 0;

  public banner = {
    image: "assets/images/banner/banner1.jpg",
    title: "CALC.banner_title",
    page: "CALC.banner_page_courier"
  }

  public hourOptions = [
    {label: '1 hodinu', value: 1},
    {label: '2 hodiny', value: 2},
    {label: '3 hodiny', value: 3},
    {label: '4 hodiny', value: 4},
    {label: '5 hodín', value: 5},
    {label: '6 hodín', value: 6},
    {label: '7 hodín', value: 7},
    {label: '8 hodín', value: 8},
    {label: '9 hodín', value: 9},
    {label: '10 hodín', value: 10},
    {label: '11 hodín', value: 11},
    {label: '12 hodín', value: 12},
  ];

  public workerTypes = {part_timer: 'Part-timer', worker: 'Worker', workaholic: 'Workaholic'};

  public salary = '0.00';
  public cleanSalary = '0.00';
  public fee = '0.00';
  public feePercentage = '0 %';
  public driverType = '';
  public calculator: Calculator;

  config: any = {};

  constructor(
    private calculatorAPI: CalculatorApi,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.config  = ConfigurationService.get();
    this.loadCalculatorData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setHourOptions(event.translations.HOURS);
      this.workerTypes = event.translations.WORKER_TYPES;
    });
  }

  ngOnDestroy(): void {
  }

  clickedDay(day, event) {
    if (event.target.checked) {
      this.days.push(day);
    } else {
      this.days = this.days.filter((d) => {
        return d !== day;
      });
    }
    this.countSalary();
  }

  setHours(item: SelectItemInterface) {
    this.hours = item.value;
    this.countSalary();
  }

  showThis() {
    return this.hours && this.days.length;
  }

  private setHourOptions(hourTrans) {
    const hourOptions = [];
    Object.keys(hourTrans).forEach((key, i) => {
      hourOptions.push({label: hourTrans[key], value: (i + 1)});
    });
    this.hourOptions = [...hourOptions];
  }

  private countSalary() {
    this.salary = '0.00';
    if (this.hours && this.days.length) {
      const salary = ((this.calculator.averageHourSalary * this.hours) * this.days.length);
      this.salary = salary.toFixed(2);
      this.fleetFee(salary);
      this.driverType = this.getDriverType(salary);
    }
  }

  private fleetFee(salary) {
    let fee = 0;
    if (salary < this.config.courierWorkerThreshold) {
      fee = (salary * this.config.courierLowPercentage / 100);
      this.feePercentage = `${this.config.courierLowPercentage} %`;
    } else if (salary >= this.config.courierWorkerThreshold) {
      fee = (salary * this.config.courierHighPercentage / 100);
      this.feePercentage = `${this.config.courierHighPercentage} %`;
    }

    this.cleanSalary = (salary - fee).toFixed(2);
    this.fee = fee.toFixed(2);
  }

  private getDriverType(salary) {
    if (salary < this.config.courierWorkerThreshold) {
      return this.workerTypes.part_timer;
    } else if (salary >= this.config.courierWorkerThreshold) {
      return this.workerTypes.worker;
    }
  }

  private loadCalculatorData() {
    this.calculatorAPI.fetchCalculator('courier').subscribe(r => {
      this.calculator = r.content.customData;
    });
  }
}
