import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { Slider1Component } from './components/sliders/slider1/slider1.component';
import { Specializations1Component } from './components/specializations1/specializations1.component';
import { GridBox1Component } from './components/boxes/grid-box1/grid-box1.component';
import { About1Component } from './components/about1/about1.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { AppRoutingModule } from '../../app-routing.module';
import { Specializations4Component } from './components/specializations4/specializations4.component';
import { ServiceBox1Component } from './components/boxes/service-box1/service-box1.component';
import { RouterModule } from '@angular/router';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderBoltComponent } from './components/loader-bolt/loader-bolt.component';

@NgModule({
  declarations: [
    LoaderComponent,
    Slider1Component,
    Specializations1Component,
    Specializations4Component,
    GridBox1Component,
    About1Component,
    InfoModalComponent,
    ServiceBox1Component,
    LanguageSwitchComponent,
    LoaderBoltComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    TranslateModule,
    // AppRoutingModule
  ],
  exports: [
    LoaderComponent,
    Slider1Component,
    Specializations1Component,
    Specializations4Component,
    GridBox1Component,
    About1Component,
    InfoModalComponent,
    ServiceBox1Component,
    LanguageSwitchComponent,
    RouterModule,
    LoaderBoltComponent
  ],
  providers: []
})
export class SectionElementsModule {

}
