<input
  type="checkbox"
  [ngClass]="{sm: (size === 'sm'), md: (size === 'md'), lg: (size === 'lg')}"
  [checked]="_VALUE"
  value="1"
  [id]="_ID"
  [disabled]="_DISABLED"
  #input
>
<label [for]="_ID" (click)="toggle()" [ngClass]="{required: required}">
  {{ label }}
</label>
<p class="help-block" *ngIf="description">
  <span class="text-info">{{ description }}</span>
</p>
<div class="help-block" *ngIf="errors">
  <span class="text-danger" *ngFor="let error of errors | keyvalue">{{ getErrorByKey(error.key) }}</span>
</div>
