import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '../../helpers/form-error-helper';

@Component({
  selector: 'app-verify-number-form',
  templateUrl: './verify-number-form.component.html',
  styleUrls: [
    './verify-number-form.component.scss'
  ]
})
export class VerifyNumberFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() validationError: boolean = false;
  @Input() lockForm = false;
  @Input() startWithCountdown = false;
  @Input() label = 'Insert Code';
  @Output() submitted = new EventEmitter<string>();
  @Output() resendCode = new EventEmitter();


  public form: FormGroup;
  public formErrors: FormErrorHelper;
  public allowResend = true;
  public countDown = 0;
  public countDownInterval;

  codeErrors = {
    required: 'Kód je nutné vyplniť',
    minlength: 'Kód musí mať 6 znakov',
    maxlength: 'Kód musí mať 6 znakov',
    //validation: 'Pri overovaní čísla došlo k chybe, ak stav pretrváva, kontaktuj nás.'
  };

  ngOnInit(): void {
    this.form = this.buildForm();
    this.formErrors = new FormErrorHelper(this.form);

    if (this.startWithCountdown) {
      this.startCountDown();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.countDownInterval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['validationError'] && !changes['validationError'].firstChange && changes['validationError'].currentValue) {
      this.form.patchValue({code: ''});
      this.form.controls['code'].setErrors({validation: changes['validationError'].currentValue});
    } else if (changes['validationError'] && !changes['validationError'].firstChange && changes['validationError'].currentValue) {
      this.form.controls['code'].setErrors(null);
    }
  }

  submit() {
    if (this.form.invalid) {
      this.formErrors.markAsTouched();
      return;
    }

    this.submitted.emit(this.form.value.code);
  }

  resend() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }
    this.startCountDown();
    this.resendCode.emit();
  }

  startCountDown() {
    this.allowResend = false;
    this.countDown = 120;
    this.countDownInterval = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown === 0) {
        clearInterval(this.countDownInterval);
        this.allowResend = true;
      }
    }, 1000);
  }

  private buildForm() {
    return new FormGroup({
      code: new FormControl(
        '',
        [Validators.required, Validators.pattern(/[0-9]+/g), Validators.minLength(6), Validators.maxLength(6)]
      ),
    });
  }
}
