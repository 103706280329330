import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';

@Injectable({providedIn: 'root'})
export class CourierEarningsFilterService extends AbstractFilterService {
  constructor() {
    super();
    this.fulltextSearchFields = [];
  }
}
