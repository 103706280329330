<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <!--    <app-banner [data]="banner"></app-banner>-->
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <div class="login">

        <!-- TITLE START -->
        <div class="section-head">
          <div class="mt-separator-outer separator-center">
            <div class="mt-separator">
              <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Overenie</span> čísla</h2>
            </div>
          </div>
        </div>
        <!-- TITLE END -->

        <div class="mt-post-text">
          <p>
            Tu si môžeš overiť svoje telefónne číslo použité pri registrácii. Na overené telefónne číslo môžeš dostávať SMS notifikácie.<br><br>
            Ak chceš možeš pokračovať bez overania čísla. Číslo môžeš overiť aj neskôr v osobnej zóne.
          </p>
        </div>

        <div class="mt-post-text" *ngIf="!showForm">
          <div class="send-buttons">
            <button (click)="send()" type="submit" class="m-b15 site-button text-uppercase green m-r5">
              <span class="site-button-inr">Poslať overovací kód</span>
            </button>
            <br><br><br>
            <button (click)="continue()" type="submit" class="m-b15 site-button site-button-secondry text-uppercase m-r5">
              <span class="site-button-inr">Pokračovať bez overenia</span>
            </button>
          </div>
        </div>

        <div class="form-wrapper" *ngIf="showForm">
          <app-verify-number-form
            [validationError]="verificationError"
            [lockForm]="processing"
            [startWithCountdown]="true"
            (submitted)="afterSubmit($event)"
            (resendCode)="send()"
          ></app-verify-number-form>
        </div>
      </div>
    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <!--    <app-footer1></app-footer1>-->
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
