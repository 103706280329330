import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { RegistrationOwnersResponse } from '../model/response/registration-owners-response';
import { RegistrationOwnerResponse } from '../model/response/registration-owner.response';

@Injectable()
export class AdminRecruitmentByOwnerApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter) {
    return this.http.get<RegistrationOwnersResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/recruitment-by-owners',
        page,
        limit,
        [{ownerCode: 'ASC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<RegistrationOwnerResponse>(
      this.baseUrl + '/api/cf/administration/recruitment-by-owners/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-by-owners/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-by-owners',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-by-owners/' + id,
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
