import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';

@Injectable()
export class DefaultFilterService extends AbstractFilterService {
  constructor() {
    super();
  }

  public setOperations(operations:{[key: string]: string} = {}) {
    this.operations = Object.assign(this.operations, operations);
  }
}
