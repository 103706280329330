import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class XdebugInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.xdebug && environment.xdebugKey) {
      const duplicate = req.clone({ url: req.url + this.getXdebugSessionKey(req.url)  });
      return next.handle(duplicate);
    }

    return next.handle(req);
  }

  private getXdebugSessionKey(url) {
    if (url.indexOf('?') > -1) {
      return '&XDEBUG_SESSION_START=' + environment.xdebugKey
    }

    return '?XDEBUG_SESSION_START=' + environment.xdebugKey
  }
}
