import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Size in Bytes
 */
export function validateFileSize(val: number): ValidatorFn {

  return (c: AbstractControl): ValidationErrors | null => {

    if (!c.value) { return null; }
    if (!c.value.length) { return null; }

    let valid = true;

    for (const file of c.value) {
      if (file.size > val) {
        valid = false;
        break;
      }
    }

    return valid ? null : {
      validateFileSize: {
        valid: false
      }
    };
  };

}
