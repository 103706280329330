import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LanguageStateService } from '../../../services/language-state.service';
import { LeadListResponse } from '../model/response/lead-list.response';
import { LeadDetailResponse } from '../model/response/lead-detail.response';
import { NewLeadRequest } from '../model/request/new-lead-request';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class AdminLeadsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public fetchLeads(filter, page, limit) {
    return this.http.get<LeadListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/leads',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchLead(id) {
    return this.http.get<LeadDetailResponse>(
      this.baseUrl + '/api/cf/administration/leads/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveNew(request: NewLeadRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/leads',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
