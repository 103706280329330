export const WORKING_PLACES = {
  BA: 'Bratislava',
  KE: 'Košice',
  BB: 'Banská Bystrica',
  MI: 'Michalovce',
  NR: 'Nitra',
  NZ: 'Nové Zámky',
  TN: 'Trenčín',
  TT: 'Trnava',
  PE: 'Prešov',
  PP: 'Poprad',
  ZV: 'Zvolen',
  ZA: 'Žilina',
};
