import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { RegistrationTaxi } from '../model/registration-taxi';
import { RegistrationTaxiInterface } from '../model/response/registration-taxi.interface';
import { RegistrationCourierInterface } from '../model/response/registration-courier.interface';

@Injectable()
export class RegistrationStateApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public getState(type, id) {
    return this.http.get<RegistrationTaxiInterface|RegistrationCourierInterface>(
      this.baseUrl + `/api/cf/personal-area/registration/${id}/registration-state-${type}`,
      {headers: this.getAuthHeaders()}
    );
  }

  public startRegistration(type, userId) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/registration-state-${type}`,
      {registrationId: userId},
      {headers: this.getAuthHeaders()}
    );
  }
}
