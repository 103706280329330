import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AccessCountResponse } from '../model/response/access-count.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class AdminUserGroupsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(filter = null) {
    return this.http.get<any>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/user-groups',
        1,
        100,
        [{id: 'ASC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public create(body: {group: string}) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/user-groups',
      body,
      {headers: this.getAuthHeaders()}
    );
  }

  public update(id: number, body: {group: string}) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/user-groups/' + id,
      body,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id: number) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/user-groups/' + id,
      {headers: this.getAuthHeaders()}
    );
  }
}
