import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LanguageStateService } from '../../../services/language-state.service';

@Injectable()
export class CarContractApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public getContract(id) {
    return this.http.get<any>(
      this.baseUrl + `/api/te/v1/car-contract/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }

  public signContract(id, request: {signatureDataUrl: string}) {
    return this.http.post<any>(
      this.baseUrl + `/api/te/v1/car-contract/${id}/sign-contract`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
