import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { RecruitmentBonusEarningListResponse } from '../model/response/recruitment-bonus-earning-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class AdminRecruitmentBonusEarningsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter = null) {
    return this.http.get<RecruitmentBonusEarningListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/recruitment-bonus/earnings',
        page,
        limit,
        [{periodFrom: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  update(id, request: any) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-bonus/earnings/' + id,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  saveNew(request: any) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-bonus/earnings',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-bonus/earnings/' + id,
      {headers: this.getAuthHeaders()}
    );
  }
}
