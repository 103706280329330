import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { IndividualRecruitmentBonusRequest } from '../model/request/individual-recruitment-bonus.request';

@Injectable()
export class AdminIndividualRecruitmentBonusApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public saveIndividualBonus(request: IndividualRecruitmentBonusRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-recruitment-bonus`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public updateIndividualBonus(id: number, request: IndividualRecruitmentBonusRequest) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-recruitment-bonus/${id}`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public deleteIndividualBonus(id: number) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-recruitment-bonus/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }
}
