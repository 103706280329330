<!-- CONTENT START -->
<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">
      <div class="blog-single-wrap-width">
        <!-- BLOG START -->
        <div class="blog-post date-style-3 blog-detail text-black">

          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator">
                <h1 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">{{ 'JOIN_US.join' | translate }}</span> {{ 'JOIN_US.us' | translate }}</h1>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <div class="mt-post-title ">
            <!--              <h1 class="post-title">Pridaj sa k nám</h1>-->
          </div>

          <div class="mt-post-text">
            <p><strong>{{ 'JOIN_US.paragraph1' | translate }}</strong></p>
            <p><strong>{{ 'JOIN_US.p_student' | translate }}</strong></p>
            <p [innerHtml]="'JOIN_US.paragraph2' | translate"></p>

            <p>{{ 'JOIN_US.paragraph3' | translate }}</p>

            <h3>{{ 'JOIN_US.header3' | translate}}</h3>

            <p [innerHtml]="'JOIN_US.paragraph4' | translate"></p>

            <p [innerHtml]="'JOIN_US.paragraph5' | translate"></p>

            <p [innerHtml]="'JOIN_US.paragraph6' | translate"></p>

            <p>
              <a routerLink="/calculator/taxi" class="m-b15 site-button text-uppercase green m-r5">
                <i class="fa fa-taxi"></i>
                <span class="site-button-inr">{{ 'JOIN_US.button1' | translate}}</span>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a routerLink="/calculator/courier" class="m-b15 site-button text-uppercase green m-r5">
                <i class="fa fa-bicycle"></i>
                <span class="site-button-inr">{{ 'JOIN_US.button2' | translate}}</span>
              </a>
            </p>

            <p [innerHtml]="'JOIN_US.paragraph7' | translate"></p>

            <p>
              <a routerLink="/registration" [queryParams]="{pref: 'taxi'}" class="m-b15 site-button text-uppercase green m-r5">
                <i class="fa fa-taxi"></i>
                <span class="site-button-inr">{{ 'JOIN_US.button3' | translate}}</span>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a routerLink="/registration" [queryParams]="{pref: 'courier'}" class="m-b15 site-button text-uppercase green m-r5">
                <i class="fa fa-bicycle"></i>
                <span class="site-button-inr">{{ 'JOIN_US.button4' | translate}}</span>
              </a>
            </p>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- ABOUT COMPANY SECTION START -->
  <!--        <app-about3 [data]="about" title="1"></app-about3>  -->
  <!-- ABOUT COMPANY SECTION END -->

  <!-- OUR SPECIALLIZATION START -->
  <!--        <app-specializations4 [data]="specializations"></app-specializations4>   -->
  <!-- OUR SPECIALLIZATION END -->

  <!-- OUR TEAM START -->
  <!--        <app-team3 [data]="team"></app-team3>-->
  <!-- OUR TEAM END -->

  <!-- OUR STORY SECTION START -->
  <!--        <app-story1 [data]="story"></app-story1> -->
  <!-- OUR STORY SECTION END -->

</div>
<!-- CONTENT END -->

<!-- FOOTER START -->
<app-footer1></app-footer1>
<!-- FOOTER END -->

<app-loader></app-loader>
