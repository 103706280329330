export const CONTACT = {
  phone: '+421 907 118 277, +421 908 073 966',
  emailName: 'flotila',
  emailDomain: 'coffee-taxi.sk',
  postAddress: 'Coffee Taxi & Courier Ltd<br>organizačná zložka<br>Karpatské námestie 7770/10A<br> 831 06 Bratislava',
  copyRightShort: '&copy; 2022 '
    + ((new Date()).getFullYear() > 2022 ? '- ' +(new Date()).getFullYear() : '')
    + 'Coffee Taxi & Courier',
  copyRight: '&copy; 2022 '
    + ((new Date()).getFullYear() > 2022 ? '- ' +(new Date()).getFullYear() : '')
    + 'Coffee Taxi & Courier. Programmed by MBM ARTWORKS, v.o.s.',
  facebookUrl: 'https://www.facebook.com/CoffeeTaxiCourier',
  linkedInUrl: 'https://in.linkedin.com'
};
