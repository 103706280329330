<form class="login-form" [formGroup]="form" (ngSubmit)="submit()">

  <div
    verification-code-input
    formControlName="code"
    [label]="label"
    [errors]="formErrors.getErrorsFor('code')"
    [messages]="codeErrors"
  ></div>

  <div class="p-t20 m-b40 d-flex justify-space-between">
    <div class="resend-button">
      <button
        bootstrapButton
        type="button"
        color="primary"
        class="site-button site-button-secondry"
        [disabled]="!allowResend"
        (click)="resend()"
      >Vyžiadať nový SMS kód</button>

      <span class="countdown" [ngClass]="{hidden: !countDown}">{{ countDown || ''}}</span>
    </div>

    <div class="submit-button">
      <button
        bootstrapButton
        type="submit"
        color="primary"
        class="site-button green"
        [disabled]="lockForm"
      >Odoslať SMS kód</button>
    </div>
  </div>
</form>
