import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LoginResponseInterface } from '../model/response/login-response.interface';

@Injectable()
export class LoginApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public login(request) {
    return this.http.post<LoginResponseInterface>(
      this.baseUrl + '/api/cf/login',
      request
    );
  }
}
