import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { environment } from '../../../../environments/environment';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class ConfigurationApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public get() {
    return this.http.get<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/configuration?api_token=' + environment.appId
    );
  }
}
