<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <div class="registration">

        <!-- TITLE START -->
        <div class="section-head">
          <div class="mt-separator-outer separator-center">
            <div class="mt-separator">
              <h2 class="text-uppercase sep-line-one" [innerHtml]="'REG.pre_reg_page_title' | translate"></h2>
            </div>
          </div>
        </div>
        <!-- TITLE END -->

        <div class="mt-post-text text-center">
          <h3>{{ name }}</h3>

          <p translate="REG.pre_reg_p1"></p>
        </div>

        <div class="success-button">
          <button
            bootstrapButton
            class="site-button text-uppercase green"
            type="button"
            size="lg"
            translate="REG.i_am_continue"
            (click)="continue()"
          ></button>
        </div>

        <div class="not-me-button">
          <button
            bootstrapButton
            type="button"
            color="secondary"
            size="sm"
            translate="REG.its_not_me"
            (click)="noMe()"
          ></button>

        </div>

      </div>
    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
