import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BzDataTableComponent } from './components/data-table/bz-data-table.component';
import { BzValueLabelDirective } from './directives/bz-value-label.directive';
import { DataTableCellComponent } from './components/data-table/data-table-cell/data-table-cell.component';
import { DataTableRowComponent } from './components/data-table/data-table-row/data-table-row.component';
import { LoadableContentDirective } from './components/data-table/loadable-content-directive/loadable-content.directive';
import { DataTableIconComponent } from './components/data-table/data-table-cell/components/icon/data-table-icon.component';
import { DataTableLabelGroupComponent } from './components/data-table/data-table-cell/components/label-group/data-table-label-group.component';
import { DataTableBzIconComponent } from './components/data-table/data-table-cell/components/bz-icon/data-table-bz-icon.component';
import { CustomCellComponent } from './components/data-table/data-table-cell/components/custom-cell/custom-cell.component';
import { DataCellDirective } from './components/data-table/data-table-cell/components/custom-cell/data-cell.directive';
import {
  DataTableFlexboxGroupComponent
} from './components/data-table/data-table-flexbox-group/data-table-flexbox-group.component';
import { BrunoFormsModule } from '../forms/bruno-forms.module';
import { DataTableFaIconDirective } from './directives/fa-icon/data-table-fa-icon.directive';
import { HttpClient } from '@angular/common/http';
import { BackendTranslateLoader } from '../../services/backend-translate-loader';
import { environment } from '../../../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function createTranslationLoader(http: HttpClient) {
  return new BackendTranslateLoader(http, environment.apiUrl, 'api/translations');
}

@NgModule({
  declarations: [
    BzDataTableComponent,
    BzValueLabelDirective,
    DataTableCellComponent,
    DataTableRowComponent,
    DataTableIconComponent,
    LoadableContentDirective,
    DataTableLabelGroupComponent,
    DataTableBzIconComponent,
    CustomCellComponent,
    DataCellDirective,
    DataTableFlexboxGroupComponent,
    DataTableFaIconDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrunoFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslationLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    BzDataTableComponent,
    BzValueLabelDirective,
    DataTableRowComponent
  ],
  providers: [

  ]
})
export class BzDataTableModule {}
