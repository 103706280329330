import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '../../helpers/form-error-helper';
import { LoginApi } from '../../modules/api-module/api/login.api';
import { LoginRequest } from '../../modules/api-module/model/request/login-request';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { MenuInvalidationService } from '../../services/menu-invalidation.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manual-preview',
  templateUrl: './manual-preview.component.html',
  styleUrls: [
    './manual-preview.component.scss'
  ]
})
export class ManualPreviewComponent implements OnInit, OnDestroy {

  pdfSrc = '';
  page = 1;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.pdfSrc = 'assets/media/docs/manual-kuriera.pdf';
  }

  ngOnDestroy(): void {
  }

  pageRender(x) {

  }

  changePage(event) {
    this.page = Number(event.target.value);
  }

  nextPage()  {
    this.page += 1;
  }

  prevPage() {
    if (this.page > 1) {
      this.page -= 1;
    }
  }
}
