export class MessageModel {
  constructor(
    public type: string,
    public message: string,
    public time: number = 10000,
    public closer: boolean = true,
    public id: string = ''
  ) {
  }
}
