import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { AccessLogRequest } from '../model/request/access-log-request';

@Injectable()
export class AccessLoggingApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public save(request: AccessLogRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/cf/log-access',
      request
    );
  }
}
