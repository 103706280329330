import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { MonthlyDeliveryIncomeResponse } from '../model/response/monthly-delivery-income.response';
import { MonthlyDeliveryIncomeDetailResponse } from '../model/response/monthly-delivery-income-detail.response';

@Injectable()
export class MonthlyBillingApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchIncome(year, month) {
    return this.http.get<MonthlyDeliveryIncomeDetailResponse>(
      this.baseUrl + '/api/cf/personal-area/monthly-income',
      {headers: this.getAuthHeaders(), params: {year, month}}
    );
  }

  public fetchAdvances(periodFrom, periodTo) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/personal-area/advances'
      , {headers: this.getAuthHeaders(), params: {periodFrom, periodTo}}
    );
  }

  public fetchDeductions(periodFrom, periodTo) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/personal-area/deductions'
      , {headers: this.getAuthHeaders(), params: {periodFrom, periodTo}}
    );
  }
}
