import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { ContractDataResponse } from '../model/response/contract-data-response';

@Injectable()
export class RegistrationContractApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public getContract(id) {
    return this.http.get<ContractDataResponse>(
      this.baseUrl + `/api/cf/personal-area/registration/${id}/contract-data`,
      {headers: this.getAuthHeaders()}
    );
  }
}
