<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <!--    <app-banner [data]="banner"></app-banner>-->
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <!-- TITLE START -->
      <div class="section-head">
        <div class="mt-separator-outer separator-center">
          <div class="mt-separator">
            <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Manuál</span> kuriéra</h2>
          </div>
        </div>
      </div>
      <!-- TITLE END -->

      <pdf-viewer [src]="pdfSrc"
                  [render-text]="true"
                  [original-size]="true"
                  [fit-to-page]="true"
                  [show-all]="false"
                  [(page)]="page"
                  style="width: 1000px; height: 580px;"
      ></pdf-viewer>

      <div class="pdf-paging">
        <div class="prev">
          <button
            type="button"
            class="site-button small black"
            (click)="prevPage()"
          ><i class="fa fa-chevron-left"></i></button>
        </div>
        <div class="current">
          <input type="text" [(ngModel)]="page" class="form-control" (change)="changePage($event)"> z 61
        </div>
        <div class="next">
          <button
            type="button"
            class="site-button small black"
            (click)="nextPage()"
          ><i class="fa fa-chevron-right"></i></button>
        </div>
      </div>
    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <!--    <app-footer1></app-footer1>-->
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
