import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallSpinnerComponent } from './small-spinner.component';
import { NormalSpinnerComponent } from './normal-spinner.component';
import { LargeSpinnerComponent } from './large-spinner.component';
import { XLargeSpinnerComponent } from './xlarge-spinner.component';

@NgModule({
  declarations: [
    SmallSpinnerComponent,
    NormalSpinnerComponent,
    LargeSpinnerComponent,
    XLargeSpinnerComponent
  ],
  exports: [
    SmallSpinnerComponent,
    NormalSpinnerComponent,
    LargeSpinnerComponent,
    XLargeSpinnerComponent
  ],
  imports: [
    CommonModule
  ],
  providers: []
})
export class SpinnersModule {}
