import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AuthService } from '../../../services/auth.service';
import { DocumentListResponse } from '../model/response/document-list.response';
import { DocumentResponseInterface } from '../model/response/document-response.interface';
import { DocumentResponse } from '../model/response/document.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import {TokenService} from '../../../services/token.service';

@Injectable()
export class AdminDocumentApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchDocs(filter, page, limit) {
    return this.http.get<DocumentListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/documents',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDoc(id) {
    return this.http.get<DocumentResponse>(
      this.baseUrl + '/api/cf/administration/documents/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDocByType(type) {
    return this.http.get<DocumentResponse>(
      this.baseUrl + '/api/cf/administration/document-by-type/' + type + '?param=type',
      {headers: this.getAuthHeaders()}
    );
  }

  public approveDoc(id) {
    return this.http.post<DocumentResponse>(
      this.baseUrl + '/api/cf/administration/documents/' + id + '/approve',
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public declineDoc(id, reason) {
    return this.http.post<DocumentResponse>(
      this.baseUrl + '/api/cf/administration/documents/' + id + '/reject',
      {reason},
      {headers: this.getAuthHeaders()}
    );
  }

  public uploadDocument(formData, regID) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/registration/${regID}/document`,
      formData,
      {headers: this.getAuthHeaders()}
    );
  }

  public uploadAttachment(formData, regID) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/registration/${regID}/attachment`,
      formData,
      {headers: this.getAuthHeaders()}
    );
  }

  public deleteAttachment(id) {
    return this.http.delete<DocumentResponse>(
      this.baseUrl + '/api/cf/administration/documents/' + id + '/remove',
      {headers: this.getAuthHeaders()}
    );
  }

  public getDownloadUrl(id) {
    const token = TokenService.getInstance().get();
    return this.baseUrl + '/cf/documents/' + id + '/download?access_token=' + token;
  }
}
