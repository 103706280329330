<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <div class="calculator" *ngIf="calculatorData">

        <!-- TITLE START -->
        <div class="section-head">
          <div class="mt-separator-outer separator-left">
            <div class="mt-separator">
              <h2 class="text-uppercase sep-line-one " [innerHtml]="'CALC.header' | translate"></h2>
            </div>
          </div>
          <h3 [innerHtml]="'CALC.header3' | translate"></h3>
        </div>
        <!-- TITLE END -->

        <div class="question-row d-flex">
          <div class="question">
            <span translate="">CALC.avg_hour_salary</span>: <sup>*</sup>
          </div>
          <div class="answer">
            <strong style="font-size: 22px">{{ basicAvgSalary }} €</strong>
          </div>
        </div>

        <div class="question-row d-flex">
          <div>
            <button
              type="button"
              class="site-button button-sm"
              [ngClass]="{black: !townActive.rest, green: townActive.rest}"
              (click)="switchSalaryByTown('rest')"
            >{{ 'CALC.other_cities' | translate }}</button>
          </div>
          <div style="margin: 0 12px">
            <button
              type="button"
              class="site-button button-sm"
              [ngClass]="{black: !townActive.ba, green: townActive.ba}"
              (click)="switchSalaryByTown('ba')"
            >{{ 'Bratislava' }}</button>
          </div>
          <div>
            <button
              type="button"
              class="site-button button-sm"
              [ngClass]="{black: !townActive.ke, green: townActive.ke}"
              (click)="switchSalaryByTown('ke')"
            >{{ 'Košice' }}</button>
          </div>
        </div>

        <div class="question-row d-flex">
          <div class="question">
            <span translate="">CALC.avg_drive_salary</span>: <sup>**</sup>
          </div>
          <div class="answer">
            <strong style="font-size: 22px">{{ calculatorData.averageDriveSalary.toFixed(2) }} €</strong>
          </div>
        </div>

        <div class="question-row d-flex">
          <div class="question" translate="">
            CALC.which_days
          </div>
          <div class="answer">
            <div class="days d-flex flex-wrap">
              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.monday' | translate"
                  id="monday"
                  size="md"
                  (change)="clickedDay('Mon', $event)"></div>
              </div>
              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.tuesday' | translate"
                  id="tuesday"
                  size="md"
                  (change)="clickedDay('Tue', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.wednesday' | translate"
                  id="wednesday"
                  size="md"
                  (change)="clickedDay('Wed', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.thursday' | translate"
                  id="thursday"
                  size="md"
                  (change)="clickedDay('Thu', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.friday' | translate"
                  id="friday"
                  size="md"
                  (change)="clickedDay('Fri', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.saturday' | translate"
                  id="saturday"
                  size="md"
                  (change)="clickedDay('Sat', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.sunday' | translate"
                  id="sunday"
                  size="md"
                  (change)="clickedDay('Sun', $event)"></div>
              </div>

            </div>
          </div>
        </div>
        <div class="question-row d-flex">
          <div class="question" translate="">
            CALC.how_many_hours
          </div>
          <div class="answer">
            <div class="hour-select">
              <div
                form-select
                [options]="hourOptions"
                [placeholder]="'CALC.select_hour_amount' | translate"
                (onInputChange)="setHours($event)"
              ></div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex">
          <div class="question">
            <span translate="CALC.drive_bonus_question"></span> <span class="pointer pointer-hover" data-toggle="modal" data-target="#bonusInfoModal"><i class="fa fa-info-circle"></i></span>
          </div>
          <div class="answer">
            <div
              form-checkbox
              [label]="'CALC.car_sticker' | translate"
              id="advertisement"
              size="md"
              (change)="carAdvert($event)"></div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="driverType && showThis()">
          <div class="question" translate="CALC.what_type_am_i"></div>
          <div class="answer">
            <div class="text-info font-22">{{ driverType }}</div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.brutto_income"></span>:
          </div>
          <div class="answer">
            <div class="salary-amount">{{ bruttoSalary }} €</div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="advertisementOnCar && showThis()">
          <div class="question">
            <span translate="CALC.bolt_bonus"></span> {{ boltBonusAmountPercentage }}:
          </div>
          <div class="answer">
            <div class="fee-block d-flex">
              <div class="bonus">{{ boltBonusAmount }}</div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="advertisementOnCar && showThis()">
          <div class="question">
            <span translate="CALC.brutto_income_w_bonus"></span>:
          </div>
          <div class="answer">
            <div class="salary-amount">{{ bruttoSalaryWithBonus }} €</div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span class="bolt-color">Bolt</span> {{ 'CALC.app_commission' | translate }} {{ boltFeePercentage }} %: <span class="pointer pointer-hover" data-toggle="modal" data-target="#boltAppModal"><i class="fa fa-info-circle"></i></span>
          </div>
          <div class="answer">
            <div class="fee-block d-flex">
              <div class="fee">{{ boltFee }} €</div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.fleet_fee"></span>&nbsp;{{ feePercentage }}: <span class="pointer pointer-hover" data-toggle="modal" data-target="#fleetFeeModal"><i class="fa fa-info-circle"></i></span>
          </div>
          <div class="answer">
            <div class="fee-block d-flex">
              <div class="fee">{{ fee }} €</div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.netto_income"></span>:<sup>***</sup>
          </div>
          <div class="answer">
            <div class="clear-salary-amount d-flex">
              <div class="amount">{{ cleanSalary }} €</div>
              <div class="registration">
                <a
                  routerLink="/registration"
                  [queryParams]="{pref: 'taxi'}"
                  class="m-b15 site-button-secondry btn-effect m-r15 text-uppercase"
                  translate="CALC.want_to_register"
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-post-text">
          <p><sup>*</sup>) <span [innerHtml]="'CALC.estimated_hourly_earnings' | translate:calculatorData"></span></p>
          <p><sup>**</sup>) <span translate="CALC.estimated_earnings_for_1_drive"></span>: <strong>{{ calculatorData.averageDriveSalary.toFixed(2) }} €</strong>, {{'CALC.estimated_earnings_for_1_drive_calculated' | translate}}.</p>
          <p><sup>***</sup>) <span translate="CALC.net_income_info"></span></p>
        </div>
      </div>

    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>

<div app-info-modal id="bonusInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" [title]="'CALC.bonus_title' | translate" *ngIf="calculatorData">
  <div class="bonus-info">
    <div class="info-row">
      <p [innerHtml]="'CALC.bonus_100' | translate:calculatorData"></p>
    </div>
    <div class="info-row">
      <p [innerHtml]="'CALC.bonus_120' | translate:calculatorData"></p>
    </div>
    <div class="info-row">
      <p [innerHtml]="'CALC.bonus_140' | translate:calculatorData"></p>
    </div>
  </div>
  <p><span class="text-warning" [innerHtml]="'CALC.bonus_disclaimer' | translate"></span></p>
</div>

<div app-info-modal id="fleetFeeModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" [title]="'CALC.fleet_fees' | translate" *ngIf="calculatorData">
  <div class="fee-info">
    <div class="info-row bg-danger p-a15">
      <h5 translate="">WORKER_TYPES.part_timer</h5>
      <hr>
      <p>
        <span [innerHtml]="'CALC.modal_fleet_fee_week_earnings' | translate"></span>&nbsp;<strong class="text-info">0 - {{ config.taxiWorkerThreshold - 1}} €</strong><br>
        <span [innerHtml]="'CALC.modal_fleet_fee_part_timer' | translate:config"></span>
      </p>
    </div>
    <div class="info-row bg-success p-a15">
      <h5 translate="">WORKER_TYPES.worker</h5>
      <hr>
      <p>
        <span [innerHtml]="'CALC.modal_fleet_fee_week_earnings' | translate"></span>&nbsp;<strong class="text-info">{{ config.taxiWorkerThreshold }} - {{ config.taxiWorkaholicThreshold - 1}} €</strong><br>
        <span [innerHtml]="'CALC.modal_fleet_fee_worker' | translate:config"></span>
      </p>
    </div>
    <div class="info-row bg-warning p-a15">
      <h5 translate="">WORKER_TYPES.workaholic</h5>
      <hr>
      <p>
        <span [innerHtml]="'CALC.modal_fleet_fee_week_earnings' | translate"></span>&nbsp;<strong class="text-info">{{ config.taxiWorkaholicThreshold }} € a viac</strong> <br>
        <span [innerHtml]="'CALC.modal_fleet_fee_workaholic' | translate:config"></span>
      </p>
    </div>
  </div>
</div>

<div app-info-modal id="boltAppModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" [title]="'CALC.modal_bolt_app_commission' | translate" *ngIf="calculatorData">
  <p [innerHtml]="'CALC.modal_bolt_app_disclaimer' | translate: calculatorData"></p>
</div>

