import { Subject } from 'rxjs';

export class AbstractFilterService {

  protected filterValues: {[key: string]: any} = {};
  protected filterChanged = new Subject<{[key: string]: any}>();
  protected operations: {[key: string]: string} = {
    fulltext: 'like'
  };
  protected fulltextSearchFields = [];

  setFilterFromQuery(params) {

  }

  getActualFilters() {
    return this.filterValues;
  }

  initFilter(data: {[key: string]: any}) {
    this.filterValues = data;
  }

  setFilterValues(data: {[key: string]: any}) {
    this.filterValues = data;
    this.filterChanged.next({...this.filterValues});
  }

  appendFilterValues(data: {[key: string]: any}) {
    this.filterValues = {...this.filterValues, ...data};
    this.filterChanged.next({...this.filterValues});
  }

  setFilterValue(key, value) {
    if (value === null || value === undefined || String(value).trim() === '') {
      this.removeFilterValue(key);
      return;
    }
    this.filterValues[key] = value;
    this.filterChanged.next({...this.filterValues});
  }

  removeFilterValue(key) {
    delete this.filterValues[key];
    this.filterChanged.next({...this.filterValues});
  }

  filterChangedObs() {
    return this.filterChanged;
  }

  cleanFilter() {
    this.destroyFilter();
    this.filterChanged.next({...this.filterValues});
  }

  destroyFilter() {
    this.filterValues = {};
  }

  getFilterRequestObject() {
    const filters = {};
    const groups = [];

    Object.keys(this.filterValues).forEach(key => {

      // handle date range
      if (key === 'created' || key === 'updated') {
        const [dateFrom, dateTo] = this.filterValues[key].split('|');
        filters[key] = {op: 'between', value: [dateFrom, dateTo]};
        return;
      }

      if (key === 'fulltext') {
        const likeGroup = {op: 'or', filters: {}};

        this.fulltextSearchFields.forEach(field => {
          likeGroup.filters[field] = {op: 'like', value: this.filterValues[key].trim()};
        });

        groups.push(likeGroup);
        return;
      }

      if (typeof this.filterValues[key] === 'string' && this.getOperation(key) === 'between') {
        const inValuesTemp = this.filterValues[key].split('|');
        if (inValuesTemp.length > 1) {
          const inValues = this.clearInValues(inValuesTemp);
          filters[key] = {value: inValues, op: 'between'};
          return;
        }
      } else if (this.getOperation(key) === 'in' && Array.isArray(this.filterValues[key])) {
        const inValuesTemp = this.filterValues[key];
        if (inValuesTemp.length > 0) {
          const inValues = this.clearInValues(inValuesTemp);
          filters[key] = {value: inValues.join('|'), op: 'in'};
        }
      } else if (typeof this.filterValues[key] === 'string') {
        const inValuesTemp = this.filterValues[key].split('|');
        if (inValuesTemp.length > 1) {
          const inValues = this.clearInValues(inValuesTemp);
          filters[key] = {value: inValues.join('|'), op: 'in'};
          return;
        }
        filters[key] = {op: this.getOperation(key), value: this.filterValues[key].trim()};
      } else if (typeof this.filterValues[key] === 'boolean') {
        filters[key] = {op: 'eq', value: this.filterValues[key]};
      } else {
        filters[key] = {op: this.getOperation(key), value: this.filterValues[key]};
      }
    });

    if (!Object.keys(filters).length && groups.length) {
      return groups;
    }

    return Object.keys(filters).length ? [{op: 'and', filters, groups}] : null;
  }

  protected createFulltextLikeGroup(value) {

  }

  protected getOperation(key) {
    return this.operations[key] || 'eq';
  }

  protected clearInValues(values) {
    const out = [];

    values.forEach(v => {
      if (v.trim() !== '') {
        out.push(v.trim());
      }
    });

    return out;
  }
}
