import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApi } from '../../modules/api-module/api/auth.api';
import { TokenService } from '../../services/token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { MenuInvalidationService } from '../../services/menu-invalidation.service';

@Component({
  selector: 'app-auth-component',
  template: ''
})
export class AuthComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authAPI: AuthApi,
    private authService: AuthService,
    private menuInvalidation: MenuInvalidationService
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.fragment) {
      this.fetchProfile(this.route.snapshot.fragment);
      return;
    }

    this.router.navigate(['/']);
  }

  private fetchProfile(accessToken) {
    this.authAPI.getProfile(accessToken).subscribe(
      r => {
        if (r.code === 200) {
          TokenService.getInstance().set(accessToken);
          this.authService.setProfile(r.content);

          const navigateTo = (r.content.role === 'admin' || r.content.role === 'editor')
            ? '/administration'
            : '/personal-area/registration-state';

          this.router.navigate([navigateTo]).then(() => {
            this.menuInvalidation.reloadMenu();
          });

          return;
        }
        this.router.navigate(['/']).then(() => {
          this.menuInvalidation.reloadMenu();
        });
      },
      e => {
        console.warn(e);
        if (e instanceof HttpErrorResponse && e.status === 401) {
          TokenService.getInstance().clearToken();
        }
        this.router.navigate(['/']);
      }
    )
  }
}
