import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningReportListResponse } from '../model/response/earning-report-list.response';
import { DriverMovementListResponse } from '../model/response/driver-movement-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { Invoice } from '../model/invoice';

@Injectable()
export class AdminInvoicesApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter = null) {
    return this.http.get<DriverMovementListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/invoices',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public saveInvoice(request: Invoice) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/invoices'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  public updateInvoice(request: Invoice) {
    return this.http.put<any>(
      this.baseUrl + '/api/cf/administration/invoices/' + request.id
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  public removeInvoice(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/invoices/' + id
      , {headers: this.getAuthHeaders()}
    );
  }

  public printInvoice(id) {
    return this.http.get<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/invoices/' + id + '/print'
      , {headers: this.getAuthHeaders()}
    );
  }

  generateInvoices(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/invoices/generate'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  sendInvoices(request: {invoices: number[]}) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/invoices/send'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }
}
