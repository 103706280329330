import { Component, OnInit } from '@angular/core';
import { SelectItemInterface } from '../../modules/forms/models/select-item.interface';
import { CalculatorApi } from '../../modules/api-module/api/calculator.api';
import { Calculator } from '../../modules/api-module/model/calculator';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-calculator-taxi-page',
  templateUrl: './calculator-taxi.component.html',
  styleUrls: [
    './calculator-taxi.component.scss'
  ]
})
export class CalculatorTaxiComponent implements OnInit {

  private days = [];
  private hours = 0;

  public banner = {
    image: "assets/images/banner/banner2.jpg",
    title: "CALC.banner_title",
    page: "CALC.banner_page_taxi"
  }

  public hourOptions = [
    {label: '1 hodinu', value: 1},
    {label: '2 hodiny', value: 2},
    {label: '3 hodiny', value: 3},
    {label: '4 hodiny', value: 4},
    {label: '5 hodín', value: 5},
    {label: '6 hodín', value: 6},
    {label: '7 hodín', value: 7},
    {label: '8 hodín', value: 8},
    {label: '9 hodín', value: 9},
    {label: '10 hodín', value: 10},
    {label: '11 hodín', value: 11},
    {label: '12 hodín', value: 12},
  ];
  public workerTypes = {part_timer: 'Part-timer', worker: 'Worker', workaholic: 'Workaholic'};

  public bruttoSalary = '0.00';
  public bruttoSalaryWithBonus = '0.00';
  public boltFee = '0.00';
  public boltBonusAmountPercentage = '0 %';
  public boltBonusAmount = '0 €';
  public cleanSalary = '0.00';
  public fee = '0.00';
  public feePercentage = '0 %';
  public boltFeePercentage = 25;
  public advertisementOnCar = false;
  public closeResult = '';
  public driverType = '';
  public calculatorData: Calculator;
  public basicAvgSalary: number;
  public townActive = {
    rest: true,
    ba: false,
    ke: false
  };

  config: any = {};

  constructor(
    private calculatorApi: CalculatorApi,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.config  = ConfigurationService.get();
    this.loadCalculatorData();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setHourOptions(event.translations.HOURS);
      this.workerTypes = event.translations.WORKER_TYPES;
    });
  }

  switchSalaryByTown(town) {
    switch(town) {
      case 'ba':
        this.basicAvgSalary = this.calculatorData.avgHourSalaryBA;
        break;
      case 'ke':
        this.basicAvgSalary = this.calculatorData.avgHourSalaryKE;
        break;
      default:
        this.basicAvgSalary = this.calculatorData.averageHourSalary;
        break;
    }
    Object.keys(this.townActive).forEach(k => (this.townActive[k] = false));
    this.townActive[town] = true;
    this.countSalary();
  }

  carAdvert(event) {
    this.advertisementOnCar = event.target.checked;
    this.countSalary();
  }

  clickedDay(day, event) {
    if (event.target.checked) {
      this.days.push(day);
    } else {
      this.days = this.days.filter((d) => {
        return d !== day;
      });
    }
    this.countSalary();
  }

  setHours(item: SelectItemInterface) {
    this.hours = item.value;
    this.countSalary();
  }

  showThis() {
    return this.hours && this.days.length;
  }

  openFleetModal(modal) {
    this.modalService.open(modal);
  }

  private setHourOptions(hourTrans) {
    const hourOptions = [];
    Object.keys(hourTrans).forEach((key, i) => {
      hourOptions.push({label: hourTrans[key], value: (i + 1)});
    });
    this.hourOptions = [...hourOptions];
  }

  private countSalary() {
    const { calculatorData } = this;
    this.bruttoSalary = '0.00';
    if (this.hours && this.days.length) {
      const bruttoSalary = ((this.basicAvgSalary * this.hours) * this.days.length);
      this.bruttoSalary = bruttoSalary.toFixed(2);
      const bruttoSalaryWithBonus = this.boltBonus(bruttoSalary);
      this.bruttoSalaryWithBonus = bruttoSalaryWithBonus.toFixed(2);
      const boltCleanSalary = (bruttoSalaryWithBonus * (100 - calculatorData.boltFeePercentage) / 100);
      this.boltFee = (bruttoSalaryWithBonus * calculatorData.boltFeePercentage / 100).toFixed(2);
      this.fleetFee(bruttoSalaryWithBonus, boltCleanSalary);
      this.driverType = this.getDriverType(bruttoSalaryWithBonus);
    }
  }

  private boltBonus(salary) {
    if (!this.advertisementOnCar) {
      return salary;
    }

    const { calculatorData } = this;
    const numberOfDrives = Math.floor(salary / calculatorData.averageDriveSalary);

    let bonusAmountPerc;

    if (numberOfDrives < calculatorData.lowBonusNumberOfDrives) {
      bonusAmountPerc = 0;
    }
    else if (numberOfDrives >= calculatorData.lowBonusNumberOfDrives && numberOfDrives < calculatorData.middleBonusNumberOfDrives) {
      bonusAmountPerc = calculatorData.drivesLowBonusPercentage;
    } else if (numberOfDrives >= calculatorData.middleBonusNumberOfDrives && numberOfDrives < calculatorData.highBonusNumberOfDrives) {
      bonusAmountPerc = calculatorData.drivesMiddleBonusPercentage;
    } else if (salary >= calculatorData.highBonusNumberOfDrives) {
      bonusAmountPerc = calculatorData.drivesHighBonusPercentage;
    } else {
      return salary;
    }

    this.boltBonusAmountPercentage = `${bonusAmountPerc} %`;
    this.boltBonusAmount = (salary * (bonusAmountPerc / 100)).toFixed(2) + ' €';
    return Math.round(salary * (1 + (bonusAmountPerc / 100)) * 100) / 100;
  }

  private fleetFee(grossSalary, boltCleanSalary) {
    let fee = 0;
    if (grossSalary < this.config.taxiWorkerThreshold) {
      fee = (grossSalary * this.config.taxiLowPercentage / 100);
      this.feePercentage = `${this.config.taxiLowPercentage} %`;
    } else if (grossSalary >= this.config.taxiWorkerThreshold && grossSalary < this.config.taxiWorkaholicThreshold) {
      fee = (grossSalary * this.config.taxiMiddlePercentage / 100);
      this.feePercentage = `${this.config.taxiMiddlePercentage} %`;
    } else if (grossSalary >= this.config.taxiWorkaholicThreshold) {
      fee = (grossSalary * this.config.taxiHighPercentage / 100);
      this.feePercentage = `${this.config.taxiHighPercentage} %`;
    }

    this.cleanSalary = (boltCleanSalary - fee).toFixed(2);
    this.fee = fee.toFixed(2);
  }

  private getDriverType(salary) {
    if (salary < this.config.taxiWorkerThreshold) {
      return this.workerTypes.part_timer;
    } else if (salary >= this.config.taxiWorkerThreshold && salary < this.config.taxiWorkaholicThreshold) {
      return this.workerTypes.worker;
    } else if (salary >= this.config.taxiWorkaholicThreshold) {
      return this.workerTypes.workaholic;
    }
  }

  private loadCalculatorData() {
    this.calculatorApi.fetchCalculator('taxi').subscribe(r => {
      this.calculatorData = r.content.customData;
      this.basicAvgSalary = this.calculatorData.averageHourSalary;
    });
  }
}
