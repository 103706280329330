import { Component, Inject, AfterViewInit, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Profile } from './modules/api-module/model/profile';
import { TranslateService } from '@ngx-translate/core';
import { LanguageStateService } from './services/language-state.service';
import { PAGE_LANGUAGES } from './constants/page-languages';
import { environment } from '../environments/environment';
import { AccessLoggingService } from './services/access-logging.service';
import { ConfigurationApi } from './modules/api-module/api/configuration.api';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './modules/messaging/alert.service';

declare var jQuery: any;
declare var initializeOnReady: any;
declare var initializeOnLoad: any;
declare var shortCodesOnReady: any;
declare var mobile_nav: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'coffee-taxi';
  loggedUser: Profile;
  configLoaded = false;
  private signOutTO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private languageStateService: LanguageStateService,
    private accessLog: AccessLoggingService,
    private configAPI: ConfigurationApi,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.updateTheme(event.url);
      });
  }

  ngOnInit() {
    this.loadConfig();
    this.setLanguages();
    this.authService.user.subscribe(profile => {
      this.loggedUser = profile;
    });
    this.authService.loadProfile();

    this.route.queryParams.subscribe(params => {
      const t = setTimeout(() => {
        if (params['etarget']) {
          this.accessLog.saveLog('etarget', params['etarget']);
        } else if (params['fbclid']) {
          this.accessLog.saveLog('facebook', params['fbclid']);
        }
        clearTimeout(t);
      }, 1000);

      if (this.signOutTO) {
        clearTimeout(this.signOutTO);
      }
      if (params['signedOut']) {
        this.router.navigate(['/']).then(() => {
          this.signOutTO = setTimeout(() => {
            this.translate.get('You have been signed out due inactivity').subscribe(t => {
              this.alertService.error(t, 86400000);
            })
            clearTimeout(this.signOutTO);
          }, 3000);
        });
      }

      this.setLanguages(params['lang'] || null);
    });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.reinitializeThings();
      });
  }

  reinitializeThings() {
    (function ($) {
      setTimeout(function () {
        initializeOnLoad();
        initializeOnReady();
        shortCodesOnReady();
      }, 1000);
    })(jQuery);
  }

  private setLanguages(langCode = null) {
    // const defaultLangConfig = LANG_CONFIG.general;
    const available = this.languageStateService.getAvailableLanguages();
    const active = Object.keys(PAGE_LANGUAGES);
    const invalidate = ! active.every(key => {
      if (!this.languageStateService.getLang()) {
        return false;
      }

      return available.findIndex(item => item.abbr === key) !== -1;
    });

    if (invalidate) {
      this.languageStateService.storeLang(environment.defaultLanguage);
      this.languageStateService.storeAvailableLanguages([
        PAGE_LANGUAGES.SK,
        PAGE_LANGUAGES.EN,
        PAGE_LANGUAGES.RU
      ]);
    }

    if (langCode && PAGE_LANGUAGES[langCode.toUpperCase()]) {
      this.languageStateService.storeLang(langCode.toLowerCase());
    }

    const availableLangCodes = Object.keys(PAGE_LANGUAGES)
      .map(lang => {
        return PAGE_LANGUAGES[lang].code;
      });

    this.translate.addLangs(availableLangCodes);
    this.translate.setDefaultLang(this.languageStateService.getLang());
    this.translate.use(this.languageStateService.getLang());
  }

  updateTheme(url: string) {
    if(url == "/index4") {
      this.loadStyle('skin-7');
    } else {
      this.loadStyle('skin-4');
    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = "assets/css/skin/" + styleName + ".css";
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = "assets/css/skin/" + `${styleName}` + ".css";

      head.appendChild(style);
    }
  }

  private loadConfig() {
    this.configAPI.get().subscribe(r => {
      ConfigurationService.set(r.content);
      this.configLoaded = true;
    });
  }
}
