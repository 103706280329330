import { NgModule } from '@angular/core';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule as NgFormsModule } from '@angular/forms';
import { SelectComponent } from './components/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from './components/input/input.component';
import { NumberComponent } from './components/number/number.component';
import { PasswordComponent } from './components/password/password.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { UploadFileCanvasComponent } from './components/upload-file-canvas/upload-file-canvas.component';
import { BootstrapButtonDirective } from './directives/bootstrap-button.directive';
import { ButtonIconDirective } from './directives/button-icon.directive';
import { ButtonLabelDirective } from './directives/button-label.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { VerificationCodeComponent } from './components/verification-code/verification-code.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateInputPickerComponent } from './components/date-input-picker/date-input-picker.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    NumberComponent,
    PasswordComponent,
    PhoneNumberComponent,
    TextareaComponent,
    UploadFileComponent,
    UploadFileCanvasComponent,
    VerificationCodeComponent,
    BootstrapButtonDirective,
    ButtonIconDirective,
    ButtonLabelDirective,
    DragAndDropDirective,
    DatePickerComponent,
    DateInputPickerComponent,
    DateRangeComponent
  ],
  imports: [
    CommonModule,
    NgFormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule
  ],
    exports: [
        CheckboxComponent,
        SelectComponent,
        InputComponent,
        NumberComponent,
        PasswordComponent,
        PhoneNumberComponent,
        TextareaComponent,
        UploadFileComponent,
        UploadFileCanvasComponent,
        VerificationCodeComponent,
        BootstrapButtonDirective,
        ButtonIconDirective,
        ButtonLabelDirective,
        DragAndDropDirective,
        DateInputPickerComponent,
        DatePickerComponent
    ]
})
export class BrunoFormsModule {}
