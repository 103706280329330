<div class="page-wraper">

    <!-- CONTENT START -->
    <div class="page-content ">
        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENT START -->
        <div class="section-full p-t80 p-b50 inner-page-padding">
            <div class="container">
                <app-post-content [data]="postContent" type="0"></app-post-content>
            </div>
        </div>
        <!-- SECTION CONTENT END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
