import { Injectable } from '@angular/core';
import { AccessLoggingApi } from '../modules/api-module/api/access-logging.api';

@Injectable()
export class AccessLoggingService {

  constructor(
    private api: AccessLoggingApi
  ) {
  }

  public saveLog(source, sourceId) {
    this.api.save({source, sourceId}).subscribe(r => {

    });
  }
}
