import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { AbstractApiController } from '../../../../../../../modules/api-module/api/abstract-api-controller';
import { Configuration } from '../../../../../../../modules/api-module/configuration';
import { KilometerExpensesResponse } from '../model/response/kilometer-expenses.response';

@Injectable()
export class AdminKilometerExpensesApiService extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList() {
    return this.http.get<KilometerExpensesResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/te/admin/v1/kilometer-expenses',
        1,
        15,
        [{from: 'DESC'}]
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/te/admin/v1/kilometer-expenses',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + `/api/te/admin/v1/kilometer-expenses/${id}`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + `/api/te/admin/v1/kilometer-expenses/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }
}
