import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneVerifyApi } from '../../modules/api-module/api/phone-verify.api';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-phone-verification-page',
  templateUrl: './phone-verification.component.html',
  styleUrls: [
    './phone-verification.component.scss'
  ]
})
export class PhoneVerificationComponent implements OnInit {

  public banner = {
    image: "assets/images/banner/banner-reg.jpg",
    title: "Začni premieňať svoje plány na činy, my Ti s tým pomôžeme!",
    page: "Prihlásenie"
  }


  public processing = false;
  verificationError = false;
  showForm = false;
  private email;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private phoneVerifyAPI: PhoneVerifyApi
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      if (!StorageService.get('emailForPhoneVerification')) {
        this.router.navigate(['/personal-area/login']);
      }

      this.email = StorageService.get('emailForPhoneVerification');
      // this.sendCode(p['email']);
    });
  }

  afterSubmit(code) {
    this.verificationError = false;
    this.phoneVerifyAPI.verify(code, this.email).subscribe(
      r => {
        this.processing = false;
        if (r.code === 200) {
          this.continue(true);
        }
      },
      e => {
        if (e instanceof HttpErrorResponse && e.status >= 400) {
          this.verificationError = true;
        }
        this.processing = false;
      }
    );
  }

  continue(verifyOk = false) {
    window.location.href = 'personal-area/login?afterReg=1'
      + (verifyOk ? '&verified=1' : '');
  }

  send() {
    this.sendCode(this.email);
  }

  private sendCode(email) {
    this.processing = true;
    this.phoneVerifyAPI.send(email).subscribe(r => {
      this.processing = false;
      if (r.code === 200) {
        this.showForm = true;
      }
    }, e => {
      this.processing = false;
    });
  }
}
