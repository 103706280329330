import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageApi } from '../../../modules/api-module/api/page.api';
import { ActivatedRoute } from '@angular/router';
import { Containers } from '../../../modules/api-module/model/containers';
import { PostContent } from '../../../models/post-content';
import { Block } from '../../../modules/api-module/model/block';
import { LanguageStateService } from '../../../services/language-state.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-post-image',
  templateUrl: './post-image.component.html',
  styleUrls: ['./post-image.component.css']
})
export class PostImageComponent implements OnInit, OnDestroy {

  public banner = {
    image: "assets/images/banner/2.jpg",
    title: "Putting a plan to action, to assure your satisfaction!",
    page: "Post Image"
  }

  private lang;
  private id;
  private langSub: Subscription;

  constructor(
    private pageAPI: PageApi,
    private route: ActivatedRoute,
    private languageService: LanguageStateService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.langSub.unsubscribe();
  }

  private init() {
    this.lang = this.languageService.getLang() || environment.defaultLanguage;
    this.langSub = this.languageService.languageState.subscribe(lang => {
      this.lang = lang;
      if (this.id) {
        this.loadPage(this.id, this.lang);
      }
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.lang = this.languageService.getLang() || environment.defaultLanguage;
      const t = setTimeout(() => {
        this.loadPage(params['id'], this.lang);
        clearTimeout(t);
      }, 50);
    });
  }

  private loadPage(id, lang) {
    this.pageAPI.fetchPage(id, lang).subscribe(r => {
      this.postContent = Object.assign(this.postContent, {
        image: this.findArticleImage(r.content.containers),
        date: null,
        month: null,
        year: null,
        author: null,
        type: null,
        title: this.findArticleTitle(r.content.containers) || r.content.content_title,
        containers: this.collectContent(r.content.containers),
        tags: null,
        blogs: [],
        comments: null,
      });
      this.banner.page = r.content.content_title;
      const banner = this.filterBanner(r.content.containers);
      if (banner) {
        this.banner.image = banner.bannerImage;
        this.banner.title = banner.bannerTitle;
        this.banner.page = banner.bannerPage || r.content.content_title;
      }
    });
  }

  private collectContent(containers: Containers) {
    return [
      {class: 'content-top', content: this.filterContentBlocks(containers.content_top).map(block => block.blockHtml).join('')},
      {class: 'content-middle', content: this.filterContentBlocks(containers.content).map(block => block.blockHtml).join('')},
      {class: 'content-bottom', content: this.filterContentBlocks(containers.content_bottom).map(block => block.blockHtml).join('')}
    ];
  }

  private filterContentBlocks(blocks: Block[]) {
    return blocks.filter(block => {
      return block.type !== 'article_image' && block.type !== 'clean_content_title' && block.type !== 'coffee_taxi_page_banner' && block.type !== 'coffee_taxi_page_heading';
    });
  }

  private findArticleImage(containers: Containers) {
    let result = null;
    Object.keys(containers).forEach(section => {
      const search = containers[section].filter(b => b.type === 'article_image');
      if (search.length) {
        result = search[0].blockContent;
        return;
      }
    });

    return result;
  }

  private findArticleTitle(containers: Containers) {
    let result = null;
    Object.keys(containers).forEach(section => {
      const search = containers[section].filter(b => b.type === 'coffee_taxi_page_heading');
      if (search.length) {
        result = search[0].customData.value;
        return;
      }
    });

    return result;
  }

  private filterBanner(containers: Containers) {
    let result = null;
    Object.keys(containers).forEach(section => {
      const search = containers[section].filter(b => b.type === 'coffee_taxi_page_banner');
      if (search.length) {
        result = search[0].customData;
        return;
      }
    });

    return result;
  }

  postContent: PostContent = {
    image: "assets/images/blog/default/thum1.jpg",
    date: "20",
    month: "June",
    year: "2021",
    author: "Admin",
    type: "Traditional",
    title: "From complete turn key to project manager.",
    tags: "First Tag,Second Tag,Three Tag,Four Tag,Five Tag",
    containers: [],
    blogs: [
      {
        background: "bg-gray",
        image: "assets/images/blog/latest-blog/pic1.jpg",
        date: "14",
        month: "April",
        year: "2021",
        author: "admin",
        comments: "2",
        title: "Commercial design for project",
        subtitle: "Which is the same as saying through shrinking from toil and pain."
      },
      {
        background: "bg-gray",
        image: "assets/images/blog/latest-blog/pic2.jpg",
        date: "16",
        month: "April",
        year: "2021",
        author: "admin",
        comments: "2",
        title: "Our interior design prediction",
        subtitle: "Today we can tell you, thanks to your passion, hard work creativity."
      }
    ],
    comments: {
      count: 4,
      content: [
        {
          image: "assets/images/testimonials/pic1.jpg",
          date: "20 May 2021 at 8:15 am",
          name: "Federico",
          description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.",
          reply: []
        },
        {
          image: "assets/images/testimonials/pic4.jpg",
          date: "20 May 2021 at 8:15 am",
          name: "Schiocchet",
          description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.",
          reply: [
            {
              image: "assets/images/testimonials/pic3.jpg",
              date: "20 May 2021 at 8:15 am",
              name: "Brayden",
              description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.",
              reply: [
                {
                  image: "assets/images/testimonials/pic2.jpg",
                  date: "20 May 2021 at 8:15 am",
                  name: "Diego",
                  description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
                  reply: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
