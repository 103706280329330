import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '../../helpers/form-error-helper';
import { CaptchaApi } from '../../modules/api-module/api/captcha.api';
import { ContactMessageApi } from '../../modules/api-module/api/contact-message.api';
import { CONTACT } from '../../constants/contact';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  form: FormGroup;
  formErrors: FormErrorHelper;
  errors = {required: 'Pole je povinné', email: 'Vlož valídnu emalovú adresu'};
  captchaErrors = {...this.errors, serverError: 'Platnosť overenia vypršala', required: 'Potvrď, že si človek'};
  processing = false;
  success = false;
  contacts = CONTACT;


  constructor(
    private captchaAPI: CaptchaApi,
    private contactAPI: ContactMessageApi
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
      secInput: new FormControl('', [Validators.required])
    });
    this.formErrors = new FormErrorHelper(this.form);
    this.success = false;
  }

  public banner = {
    image: "assets/images/banner/banner1.jpg",
    title: "REG.banner_title",
    page: "CONTACT.banner_page"
  }

  submit() {
    if (this.form.invalid) {
      this.formErrors.markAsTouched();
      return;
    }
    this.processing = true;
    this.contactAPI.send(this.form.value).subscribe(r => {
      if (r.code === 200) {
        this.success = true;
        this.form.reset({name: null, email: null, message: null, secInput: this.form.value.secInput});
      }
      this.processing = false;
    }, e => {
      this.processing = false;
    });
  }

  getCaptcha(event) {
    if (event.target.checked) {
      this.processing = true;
      this.captchaAPI.fetchCaptcha().subscribe(r => {
        this.form.patchValue({secInput: r.content});
        this.processing = false;
      });
      return;
    }
    this.form.patchValue({secInput: ''});
  }
}
