import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output, Renderer2,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../_base/base-Input.component';

@Component({
  selector: '[textarea]',
  templateUrl: './textarea.component.html',
  styleUrls: ['../../styles/label.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent extends BaseInputComponent implements OnInit {
  @HostBinding('attr.id') externalId = '';

  @ViewChild('textarea' /** ng8 , {static: true}*/, { static: true }) input;

  @Input() tooltip = '';

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  _ID = '';
  _DISABLED = false;
  _VALUE = '';

  private defaultMessages = {
    required: 'Field is required',
    minlength: 'Field does not catch minimal length',
    maxlength: 'Field is over max length',
    pattern: 'Field does not match the validation pattern',
  };

  @Input()
  set value(val) {
    this._VALUE = val;
  }

  get value() {
    return this._VALUE;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.messages = Object.assign(this.defaultMessages, this.messages);
    this.renderer.addClass(this.elementRef.nativeElement, 'form-group');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._DISABLED = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj || '';
    this.input.nativeElement.value = obj || '';
  }

  doChange($event) {
    // this.value = $event.target.value;
    this.onInputChange.emit($event.target.value);
    this.onChange($event.target.value);
    this.onTouched();
  }
}
