<footer class="site-footer footer-large  footer-dark	footer-wide">
    <div class="container call-to-action-wrap bg-no-repeat bg-center  bg-primary"
        style="background-image:url(assets/images/background/bg-site.png);">
        <div class="p-a30 ">
            <div class="row">
                <div class="col-md-8 col-sm-8">
                    <div class="call-to-action-left text-black">
                        <h4 class="text-uppercase m-b10 m-t0">{{ 'FOOTER.mail_form_title' | translate }}</h4>
                        <span>{{ 'FOOTER.mail_form_text' | translate }}</span>
                    </div>
                </div>

                <div class="col-md-4 col-sm-4">
                    <div class="call-to-action-right">
                        <div class="widget_newsletter">
                            <div class="newsletter-bx">
                                <form role="search" [formGroup]="form" (ngSubmit)="submit()" class="form-inline">
                                  <div class="form-group">
                                    <div class="input-group">
                                        <input name="news-letter" class="form-control"
                                               formControlName="email"
                                            [placeholder]="'FOOTER.mail_form_placeholder' | translate" type="email">
                                        <span class="input-group-btn">
                                            <button type="submit" class="site-button"><i
                                                    class="fa fa-paper-plane-o"></i></button>
                                        </span>
                                    </div>
                                  </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER BLOCKES START -->
    <div class="footer-top overlay-wraper">
        <div class="overlay-main"></div>
        <div class="container">
            <div class="row">
                <!-- ABOUT COMPANY -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="widget widget_about">
                        <!--<h4 class="widget-title">About Company</h4>-->
                        <div *ngIf="page != '4'" class="logo-footer clearfix p-b15">
                            <a routerLink="/"><img src="assets/images/coffee-taxi-small-logo.png" alt=""></a>
                        </div>
                        <div *ngIf="page == '4'" class="logo-footer clearfix p-b15">
                            <a routerLink="/"><img src="assets/images/coffee-taxi-small-logo.png" alt=""></a>
                        </div>
                        <p>{{ 'FOOTER.thanks' | translate }}</p>

                        <ul class="social-icons  mt-social-links">
<!--                            <li><a href="https://www.google.com" class="fa fa-google"></a></li>-->
<!--                            <li><a href="https://rss.com" class="fa fa-rss"></a></li>-->
                            <li><a [href]="contact.facebookUrl" class="fa fa-facebook" target="_blank"></a></li>
<!--                            <li><a href="https://twitter.com" class="fa fa-twitter"></a></li>-->
                            <li><a [href]="contact.linkedInUrl" class="fa fa-linkedin" target="_blank"></a></li>
                        </ul>
                    </div>
                </div>

                <!-- RESENT POST -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="widget widget_address_outer">
                        <h4 class="widget-title">{{ 'FOOTER.Contacts' | translate }}</h4>
                        <ul class="widget_address">
                            <li [innerHtml]="contact.postAddress"></li>
                            <li>{{ contact.emailName }}<!---->@<!---->{{ contact.emailDomain }}</li>
                            <li>{{ contact.phone }}</li>

                        </ul>

                    </div>
                </div>

                <!-- USEFUL LINKS -->
                <div class="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                    <div class="widget widget_services inline-links">
                        <h4 class="widget-title">{{ 'FOOTER.useful' | translate }}</h4>
                        <ul>
                            <li><a routerLink="/article/6" translate="FOOTER.requirement_taxi"></a></li>
                            <li><a routerLink="/article/7" translate="FOOTER.requirement_courier"></a></li>
                            <li><a routerLink="/courier-manual" translate="FOOTER.courier_guide"></a></li>
                            <li><a routerLink="/contact">{{ 'FOOTER.Contact Us' | translate }}</a></li>
                        </ul>
                    </div>
                </div>

                <!-- TAGS -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="widget recent-posts-entry-date">
                        <h4 class="widget-title">{{ 'FOOTER.Last Posts' | translate }}</h4>
                        <div class="widget-post-bx">
                            <div class="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                <div class="mt-post-date text-center text-uppercase text-white p-tb5">
                                    <strong class="p-date">15</strong>
                                    <span class="p-month" translate="MONTH.may"></span>
                                    <span class="p-year">2022</span>
                                </div>
                                <div class="mt-post-info">
                                    <div class="mt-post-header">
                                        <h6 class="post-title"><a routerLink="/article/12" translate="FOOTER.fleet"></a></h6>
                                    </div>
                                    <div class="mt-post-meta">
                                        <ul>
                                            <li class="post-author"><i class="fa fa-user"></i>Admin</li>
<!--                                            <li class="post-comment"><i class="fa fa-comments"></i> 28</li>-->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                <div class="mt-post-date text-center text-uppercase text-white p-tb5">
                                    <strong class="p-date">15</strong>
                                    <span class="p-month" translate="MONTH.may"></span>
                                    <span class="p-year">2022</span>
                                </div>
                                <div class="mt-post-info">
                                    <div class="mt-post-header">
                                        <h6 class="post-title"><a routerLink="/article/14" translate="FOOTER.recruitment_bonus"></a></h6>
                                    </div>
                                    <div class="mt-post-meta">
                                        <ul>
                                            <li class="post-author"><i class="fa fa-user"></i>Admin</li>
<!--                                            <li class="post-comment"><i class="fa fa-comments"></i> 29</li>-->
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- NEWSLETTER -->

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->
    <div class="footer-bottom overlay-wraper">
        <div class="overlay-main"></div>
        <div class="container">
            <div class="row">
                <div class="mt-footer-bot-center">
                    <span class="copyrights-text" [innerHtml]="contact.copyRight"></span>
                </div>
            </div>
        </div>
    </div>
</footer>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':modalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-secondry">
        <h4 class="modal-title text-white">{{ success ? ('Sent' | translate) : ('Are you a robot?' | translate) }}</h4>
      </div>
      <div class="modal-body" [ngClass]="{hidden: success}">
        <div
          form-checkbox
          [label]="'I\'m not robot' | translate"
          id="secCode"
          size="lg"
          [messages]="captchaErrors"
          [errors]="formErrors.getErrorsFor('secCode')"
          (change)="getCaptcha($event)"
        ></div>
      </div>
      <div class="modal-body" [ngClass]="{hidden: !success}">
        <p class="text-success">{{ 'The request has been sent. We will answer you as soon as possible.' | translate }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
                *ngIf="!success"
                [disabled]="!showContinue || processing"
                (click)="submitConfirm()">
          {{ 'Continue' | translate }}
        </button>
        <button type="button" class="btn btn-primary"
                *ngIf="success"
                [disabled]="processing"
                (click)="close()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
