import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LanguageStateService } from '../../../services/language-state.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TempRegistrationApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public getTempRegistration(token) {
    return this.http.get<any>(
      this.baseUrl + `/api/cf/temp-registration?token=${token}&appId=${environment.appId}`
    );
  }
}
