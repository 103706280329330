import { OrderByInterface } from '../model/order-by.interface';
import { FilterInterface } from '../model/filter-interface';
import { HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';

export class AbstractApiController {
  protected baseUrl = '';

  protected prepareListRequest(
    endpointUrl: string,
    page?: number,
    limit?: number,
    orderBy?: OrderByInterface[],
    filter?: FilterInterface
  ) {
    if (page === undefined && limit === undefined  && orderBy === undefined && filter === undefined) {
      return endpointUrl;
    }

    const params = [];
    if (page !== undefined) {
      params.push(`page=${page}`);
    }

    if (limit !== undefined) {
      params.push(`limit=${limit}`);
    }

    if (orderBy) {
      params.push(`order=${JSON.stringify(orderBy)}`);
    }

    if (filter) {
      params.push(`filter=${JSON.stringify(filter)}`);
    }

    return encodeURI(endpointUrl + '?' + params.join('&'));
  }

  protected getAuthHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', TokenService.getInstance().getBearerToken());

    return headers;
  }
}
