import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';
import { BlockApi } from './api/block.api';
import { CalculatorApi } from './api/calculator.api';
import { MenuApi } from './api/menu.api';
import { PageApi } from './api/page.api';
import { CaptchaApi } from './api/captcha.api';
import { RegistrationApi } from './api/registration.api';
import { LoginApi } from './api/login.api';
import { AuthApi } from './api/auth.api';
import { RegistrationStateApi } from './api/registration-state.api';
import { LogoutApi } from './api/logout.api';
import { PatchApi } from './api/patch.api';
import { PhoneVerifyApi } from './api/phone-verify.api';
import { ContactMessageApi } from './api/contact-message.api';
import { AdminDocumentApi } from './api/admin-document.api';
import { AdminRegistrationApi } from './api/admin-registration.api';
import { RegistrationContractApi } from './api/registration-contract.api';
import { AdminRegistrationApproveApi } from './api/admin-registration-approve.api';
import { AdminContractApi } from './api/admin-contract.api';
import { AccessLoggingApi } from './api/access-logging.api';
import { SendInfoApi } from './api/send-info.api';
import { AdminAccessApi } from './api/admin-access.api';
import { AdminCourierEarningsReportApi } from './api/admin-courier-earnings-report.api';
import { CourierEarningsApi } from './api/courier-earnings.api';
import { AdminDriverMovementReportApi } from './api/admin-driver-movement-report.api';
import { ChangePasswordApi } from './api/change-password.api';
import { AdminTaxiEarningsReportApi } from './api/admin-taxi-earnings-report.api';
import { TaxiEarningsApi } from './api/taxi-earnings.api';
import { AdminTaxiEarningsApi } from './api/admin-taxi-earnings.api';
import { AdminQrGenerateApi } from './api/admin-qr-generate.api';
import { AdminCourierEarningsApi } from './api/admin-courier-earnings.api';
import { ConfigurationApi } from './api/configuration.api';
import { AdminConfigurationApi } from './api/admin-configuration.api';
import { AdminIndividualFeeApi } from './api/admin-individual-fee.api';
import { AdminMailingApi } from './api/admin-mailing.api';
import { AdminEarningsApi } from './api/admin-earnings.api';
import { EarningsApi } from './api/earnings.api';
import { PaRecruitRegistrationApi } from './api/pa-recruit-registration-api.service';
import { PaRecruitingBonusApi } from './api/pa-recruiting-bonus-api.service';
import { AdminRegistrationOwnerApi } from './api/admin-registration-owner.api';
import { AdminIndividualRecruitmentBonusApi } from './api/admin-individual-recruitment-bonus.api';
import { AdminInstantMessageApi } from './api/admin-instant-message.api';
import { AdminRecruitmentImportApi } from './api/admin-recruitment-import.api';
import { TempRegistrationApi } from './api/temp-registration.api';
import { DocumentApi } from './api/document.api';
import { AdminEnumApi } from './api/admin-enum.api';
import { AdminRegistrationNoteApi } from './api/admin-registration-note.api';
import {
  AdminCarsApi
} from '../../pages/admin/sub-modules/travel-expenses/modules/api-module/api/admin-cars-api.service';
import {
  AdminKilometerExpensesApiService
} from '../../pages/admin/sub-modules/travel-expenses/modules/api-module/api/admin-kilometer-expenses-api.service';
import {
  AdminWeeklyExpensesApiService
} from '../../pages/admin/sub-modules/travel-expenses/modules/api-module/api/admin-weekly-expenses-api.service';
import {
  AdminAverageFuelPricesApiService
} from '../../pages/admin/sub-modules/travel-expenses/modules/api-module/api/admin-average-fuel-prices-api.service';
import {
  PatchApi as TravelPatchApi
} from '../../pages/admin/sub-modules/travel-expenses/modules/api-module/api/patch-api.service';
import { CarContractApi } from './api/car-contract.api';
import { AdminRecruitersApi } from './api/admin-recruiters.api';
import { AdminMonthlyBillingApi } from './api/admin-monthly-billing.api';
import { AdminMonthlySalariesApi } from './api/admin-monthly-salaries.api';
import { AdminDeductionsApi } from './api/admin-deductions.api';
import { AdminPaidAdvancesApi } from './api/admin-paid-advances.api';
import { MonthlyBillingApi } from './api/monthly-billing.api';
import { SalariesApi } from './api/salaries.api';
import { AdminStatsApi } from './api/admin-stats.api';
import { AdminWeeklyIncomeApi } from './api/admin-weekly-income.api';
import { AdminRecruitmentBonusPaymentsApi } from './api/admin-recruitment-bonus-payments.api';
import { AdminRecruitmentBonusEarningsApi } from './api/admin-recruitment-bonus-earnings.api';
import { PaBuyingSocietyApiService } from './api/pa-buying-society-api.service';
import { AdminBuyingSocietyApiService } from './api/admin-buying-society-api.service';
import { AdminOnTradeSettingApi } from './api/admin-on-trade-setting.api';
import { AdminUserGroupsApi } from './api/admin-user-groups.api';
import { AdminRegistrationGroupsApi } from './api/admin-registration-groups.api';
import { AdminUberEarningsReportApi } from './api/admin-uber-earnings-report.api';
import { AdminUberEarningsApi } from './api/admin-uber-earnings.api';
import { AdminPointWalletApiService } from './api/admin-point-wallet-api.service';
import { AdminRecruitmentBonusCronApi } from './api/admin-recruitment-bonus-cron.api';
import { AdminRegistrationContractsApi } from './api/admin-registration-contracts.api';
import { AdminRegistrationKidsApi } from './api/admin-registration-kids.api';
import { AdminInvoicesApi } from './api/admin-invoices.api';
import {AdminRecruitmentByOwnerApi} from './api/admin-recruitment-by-owner.api';
import {AdminEarningsV2Api} from './api/admin-earnings-v2.api';
import { AdminLeadsApi } from './api/admin-leads.api';
import { AdminLeadCommentsApi } from './api/admin-lead-comments.api';
import { AdminProgramFeeApi } from './api/admin-program-fee.api';
import { AdminProgramRecruitmentBonusApi } from './api/admin-program-recruitment-bonus.api';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    BlockApi,
    CalculatorApi,
    MenuApi,
    PageApi,
    CaptchaApi,
    RegistrationApi,
    LoginApi,
    AuthApi,
    RegistrationStateApi,
    LogoutApi,
    PatchApi,
    PhoneVerifyApi,
    ContactMessageApi,
    AdminDocumentApi,
    AdminRegistrationApi,
    RegistrationContractApi,
    AdminRegistrationApproveApi,
    AdminContractApi,
    AccessLoggingApi,
    SendInfoApi,
    AdminAccessApi,
    AdminCourierEarningsReportApi,
    AdminDriverMovementReportApi,
    CourierEarningsApi,
    ChangePasswordApi,
    AdminTaxiEarningsReportApi,
    TaxiEarningsApi,
    AdminTaxiEarningsApi,
    AdminQrGenerateApi,
    AdminCourierEarningsApi,
    ConfigurationApi,
    AdminConfigurationApi,
    AdminIndividualFeeApi,
    AdminMailingApi,
    AdminEarningsApi,
    EarningsApi,
    PaRecruitRegistrationApi,
    PaRecruitingBonusApi,
    AdminRegistrationOwnerApi,
    AdminIndividualRecruitmentBonusApi,
    AdminInstantMessageApi,
    AdminRecruitmentImportApi,
    TempRegistrationApi,
    DocumentApi,
    AdminEnumApi,
    AdminRegistrationNoteApi,
    AdminCarsApi,
    AdminKilometerExpensesApiService,
    AdminWeeklyExpensesApiService,
    AdminAverageFuelPricesApiService,
    TravelPatchApi,
    CarContractApi,
    AdminRecruitersApi,
    AdminMonthlyBillingApi,
    AdminMonthlySalariesApi,
    AdminDeductionsApi,
    AdminPaidAdvancesApi,
    MonthlyBillingApi,
    SalariesApi,
    AdminStatsApi,
    AdminWeeklyIncomeApi,
    AdminRecruitmentBonusPaymentsApi,
    AdminRecruitmentBonusEarningsApi,
    PaBuyingSocietyApiService,
    AdminBuyingSocietyApiService,
    AdminOnTradeSettingApi,
    AdminUserGroupsApi,
    AdminRegistrationGroupsApi,
    AdminUberEarningsReportApi,
    AdminUberEarningsApi,
    AdminPointWalletApiService,
    AdminRecruitmentBonusCronApi,
    AdminRegistrationContractsApi,
    AdminRegistrationKidsApi,
    AdminInvoicesApi,
    AdminRecruitmentByOwnerApi,
    AdminEarningsV2Api,
    AdminLeadsApi,
    AdminLeadCommentsApi,
    AdminProgramFeeApi,
    AdminProgramRecruitmentBonusApi
  ]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import your base AppModule only.');
    }
  }
}
