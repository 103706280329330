import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { CourierEarningListResponse } from '../model/response/configuration-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';

@Injectable()
export class AdminMailingApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList() {
    return this.http.get<any>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/mailings',
        1,
        100,
        [{id: 'DESC'}]
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/mailing',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/mailing/${id}`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/mailing/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }

  public preview(id) {
    return this.http.get<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/mailing/${id}/preview`,
      {headers: this.getAuthHeaders()}
    );
  }
}
