import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { SalaryListResponse } from '../model/response/salary-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { SalaryDetailResponse } from '../model/response/salary-detail.response';

@Injectable()
export class AdminRegistrationKidsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter = null) {
    return this.http.get<SalaryListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/registration-kids',
        page,
        limit,
        [{id: 'ASC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<SalaryDetailResponse>(
      this.baseUrl + '/api/cf/administration/registration-kids/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registration-kids'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registration-kids/' + id
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registration-kids/' + id
      ,{headers: this.getAuthHeaders()}
    );
  }
}
