import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningsResponse } from '../model/response/earnings-response';
import { StatsIncomeListResponse } from '../model/response/stats-income-list.response';

@Injectable()
export class AdminStatsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchSalariesVsIncomes(year, month, owner = null) {
    const requestParams = {year, month};
    if (owner) {
      requestParams['owner'] = owner;
    }
    return this.http.get<StatsIncomeListResponse>(
      this.baseUrl + '/api/cf/administration/stats/salaries-vs-incomes',
      {headers: this.getAuthHeaders(), params: requestParams}
    );
  }

  public fetchIncomesVsSalariesMonthly(year, month, owner = null) {
    const requestParams = {year, month};
    if (owner) {
      requestParams['owner'] = owner;
    }
    return this.http.get<StatsIncomeListResponse>(
      this.baseUrl + '/api/cf/administration/stats/incomes-vs-salaries/monthly',
      {headers: this.getAuthHeaders(), params: requestParams}
    );
  }

  public fetchIncomesVsSalariesWeekly(periodFrom, periodTo, owner = null) {
    const requestParams = {periodFrom, periodTo};
    if (owner) {
      requestParams['owner'] = owner;
    }
    return this.http.get<StatsIncomeListResponse>(
      this.baseUrl + '/api/cf/administration/stats/incomes-vs-salaries/weekly',
      {headers: this.getAuthHeaders(), params: requestParams}
    );
  }
}
