import { Component, Input, OnInit } from '@angular/core';
import { MessageModel } from '../message-model';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  public info: MessageModel = null;
  public success: MessageModel = null;
  public warning: MessageModel = null;
  public error: MessageModel = null;

  private messages: {[key:string]: MessageModel} = {};
  public shownMessages: MessageModel[] = [];

  @Input() childStyle: {[p: string]: any} = {};
  @Input() childClass = '';

  constructor(
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.alertService.onMessageAdded().subscribe(message => {
      const key = String(Date.now());
      this.messages[key] = message;
      this.showMessages();

      if (message.time) {
        setTimeout(() => {
          this.remove(key);
        }, message.time);
      }
    });
  }

  remove = (key: string) => {
    delete this.messages[key];
    this.showMessages();
  }

  showMessages() {
    this.shownMessages = [...Object.keys(this.messages).map(key => {
      const message = this.messages[key];
      message['id'] = key;

      return message;
    })];
  }
}
