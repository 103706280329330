import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { ContractData } from '../modules/api-module/model/contract-data';

@Injectable()
export class LeviesService {
  private leviesEmployee: number;
  private leviesEmployer: number;
  private studentLeviesEmployeeLow: number;
  private studentLeviesEmployerLow: number;
  private studentLeviesEmployeeHigh: number;
  private studentLeviesEmployerHigh: number;
  private studentHighSalaryThreshold: number;
  private invalidLeviesEmployeeLow: number;
  private invalidLeviesEmployerLow: number;
  private invalidLeviesEmployeeHigh: number;
  private invalidLeviesEmployerHigh: number;
  private invalidHighSalaryThreshold: number;

  constructor() {
    this.leviesEmployee = ConfigurationService.get('agreementLeviesEmployeePercentage');
    this.leviesEmployer = ConfigurationService.get('agreementLeviesEmployerPercentage');
    this.studentLeviesEmployeeLow = ConfigurationService.get('studentLeviesEmployeePercentageLow');
    this.studentLeviesEmployerLow = ConfigurationService.get('studentLeviesEmployerPercentageLow');
    this.studentLeviesEmployeeHigh = ConfigurationService.get('studentLeviesEmployeePercentageHigh');
    this.studentLeviesEmployerHigh = ConfigurationService.get('studentLeviesEmployerPercentageHigh');
    this.studentHighSalaryThreshold = ConfigurationService.get('studentHighSalaryThreshold');
    this.invalidLeviesEmployeeLow = ConfigurationService.get('invalidLeviesEmployeePercentageLow');
    this.invalidLeviesEmployerLow = ConfigurationService.get('invalidLeviesEmployerPercentageLow');
    this.invalidLeviesEmployeeHigh = ConfigurationService.get('invalidLeviesEmployeePercentageHigh');
    this.invalidLeviesEmployerHigh = ConfigurationService.get('invalidLeviesEmployerPercentageHigh');
    this.invalidHighSalaryThreshold = ConfigurationService.get('invalidHighSalaryThreshold');
  }

  public calculateLevies(contractData: ContractData, weekPart = false) {
    if ((!contractData || !contractData.monthlyGrossSalary) || (contractData.contractType === 'FAKTURA')) {
      return 0;
    }

    if (contractData.contractType === 'DOHODA' && contractData.invalids) {
      return this.countInvalids(contractData.monthlyGrossSalary, weekPart);
    } else if (contractData.contractType === 'DOHODA' && !contractData.invalids) {
      return this.countAgreement(contractData.monthlyGrossSalary, weekPart);
    } else if(contractData.contractType === 'BRIGADA_STUDENT') {
      return this.countStudents(contractData.monthlyGrossSalary, weekPart, contractData.applyStudentBonus);
    }
  }

  private countInvalids(grossSalary, weekPart) {
    if (grossSalary < this.invalidHighSalaryThreshold) {
      const levyEmployee = grossSalary * (this.invalidLeviesEmployeeLow / 100);
      const levyEmployer = grossSalary * (this.invalidLeviesEmployerLow / 100);

      return (levyEmployee + levyEmployer) * (weekPart ? 0.25 : 1);
    }

    if (grossSalary >= this.invalidHighSalaryThreshold) {
      const levyEmployee = grossSalary * (this.invalidLeviesEmployeeHigh / 100);
      const levyEmployer = grossSalary * (this.invalidLeviesEmployerHigh / 100);

      return (levyEmployee + levyEmployer) * (weekPart ? 0.25 : 1);
    }
  }

  private countStudents(grossSalary, weekPart, studentBonus) {
    if ((grossSalary < this.studentHighSalaryThreshold) && studentBonus) {
      const levyEmployee = grossSalary * (this.studentLeviesEmployeeLow / 100);
      const levyEmployer = grossSalary * (this.studentLeviesEmployerLow / 100);

      return (levyEmployee + levyEmployer) * (weekPart ? 0.25 : 1);
    }

    if (grossSalary >= this.invalidHighSalaryThreshold) {
      const levyEmployee = grossSalary * (this.studentLeviesEmployeeHigh / 100);
      const levyEmployer = grossSalary * (this.studentLeviesEmployerHigh / 100);

      return (levyEmployee + levyEmployer) * (weekPart ? 0.25 : 1);
    }
  }

  private countAgreement(grossSalary, weekPart) {
      const levyEmployee = grossSalary * (this.leviesEmployee / 100);
      const levyEmployer = grossSalary * (this.leviesEmployer / 100);

      return (levyEmployee + levyEmployer) * (weekPart ? 0.25 : 1);
  }
}
