import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { AbstractApiController } from '../../../../../../../modules/api-module/api/abstract-api-controller';
import { Configuration } from '../../../../../../../modules/api-module/configuration';
import { PatchRequest } from '../../../../../../../modules/api-module/model/request/patch-request';

@Injectable()
export class PatchApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public patch(request: PatchRequest) {
    return this.http.patch<DefaultResponseInterface>(
      this.baseUrl + '/api/te/admin/v1/patch',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
