import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { RecruitNewRegistrationRequest } from '../model/request/recruit-new-registration-request';

@Injectable()
export class PaRecruitRegistrationApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public saveNew(request: RecruitNewRegistrationRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/personal-area/recruit-registration',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
