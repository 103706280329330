import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { MenuResponseInterface } from '../model/response/menu-response.interface';
import { TokenService } from '../../../services/token.service';
import { LanguageStateService } from '../../../services/language-state.service';

@Injectable()
export class MenuApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchMenu(key, lang = 'sk') {
    let options = {};
    if (TokenService.getInstance().get()) {
      options = {headers: this.getAuthHeaders()};
    }

    return this.http.get<MenuResponseInterface>(this.baseUrl + `/${lang}` + '/api/menus/' + key, options);
  }
}
