<label [for]="_ID" *ngIf="label" [ngClass]="{required: required}">{{ label }}</label>
<div class="help-block" *ngIf="description && descriptionOnTop">
  <span [innerHtml]="description" class="text-info"></span>
</div>
<textarea
  class="form-control"
  [id]="_ID"
  [disabled]="_DISABLED"
  [placeholder]="placeholder"
  [required]="required"
  (input)="doChange($event)"
  (focus)="focused()"
  (blur)="blured()"
  #textarea
>{{ value }}</textarea>
<div class="help-block" *ngIf="errors">
  <span class="text-danger" *ngFor="let error of errors | keyvalue">{{ getErrorByKey(error.key) }}</span>
</div>
<div class="help-block" *ngIf="description && !descriptionOnTop">
  <span [innerHtml]="description" class="text-info"></span>
</div>

<div class="input-tooltip" *ngIf="tooltip">
  {{ tooltip }}
  <span class="input-tooltip-pointer"></span>
</div>
