<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENT START -->
  <div class="section-full p-t50 p-b50 inner-page-padding">
    <div class="container">

      <div class="calculator" *ngIf="calculator">

        <!-- TITLE START -->
        <div class="section-head">
          <div class="mt-separator-outer separator-left">
            <div class="mt-separator">
              <h2 class="text-uppercase sep-line-one " [innerHtml]="'CALC.header' | translate"></h2>
            </div>
          </div>
          <h3 [innerHtml]="'CALC.header3_courier' | translate"></h3>
        </div>
        <!-- TITLE END -->

        <div class="question-row d-flex">
          <div class="question">
            <span translate="">CALC.avg_hour_salary</span>: <sup>*</sup>
          </div>
          <div class="answer">
            <strong style="font-size: 22px">{{ calculator.averageHourSalary }} €</strong>
          </div>
        </div>

        <div class="question-row d-flex">
          <div class="question" translate="">
            CALC.which_days
          </div>
          <div class="answer">
            <div class="days d-flex flex-wrap">
              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.monday' | translate"
                  id="monday"
                  size="md"
                  (change)="clickedDay('Mon', $event)"></div>
              </div>
              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.tuesday' | translate"
                  id="tuesday"
                  size="md"
                  (change)="clickedDay('Tue', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.wednesday' | translate"
                  id="wednesday"
                  size="md"
                  (change)="clickedDay('Wed', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.thursday' | translate"
                  id="thursday"
                  size="md"
                  (change)="clickedDay('Thu', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.friday' | translate"
                  id="friday"
                  size="md"
                  (change)="clickedDay('Fri', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.saturday' | translate"
                  id="saturday"
                  size="md"
                  (change)="clickedDay('Sat', $event)"></div>
              </div>

              <div class="day">
                <div
                  form-checkbox
                  [label]="'GENERAL.sunday' | translate"
                  id="sunday"
                  size="md"
                  (change)="clickedDay('Sun', $event)"></div>
              </div>

            </div>
          </div>
        </div>
        <div class="question-row d-flex">
          <div class="question" translate="">
            CALC.how_many_hours
          </div>
          <div class="answer">
            <div class="hour-select">
              <div
                form-select
                [options]="hourOptions"
                [placeholder]="'CALC.select_hour_amount' | translate"
                (onInputChange)="setHours($event)"
              ></div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="driverType && showThis()">
          <div class="question" translate="CALC.what_type_am_i"></div>
          <div class="answer">
            <div class="text-info font-22">{{ driverType }}</div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.brutto_income"></span>:
          </div>
          <div class="answer">
            <div class="salary-amount">{{ salary }} €</div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.fleet_fee"></span> {{ feePercentage }}: <span class="pointer pointer-hover" data-target="#fleetFeeModal" data-toggle="modal"><i class="fa fa-info-circle"></i></span>
          </div>
          <div class="answer">
            <div class="fee-block d-flex">
              <div class="fee">{{ fee }} €</div>
            </div>
          </div>
        </div>

        <div class="question-row d-flex" *ngIf="showThis()">
          <div class="question">
            <span translate="CALC.netto_income"></span>:<sup>**</sup>
          </div>
          <div class="answer">
            <div class="clear-salary-amount d-flex">
              <div class="amount">{{ cleanSalary }} €</div>
              <div class="registration">
                <a
                  routerLink="/registration"
                  [queryParams]="{pref: 'courier'}"
                  class="m-b15 site-button-secondry btn-effect m-r15 text-uppercase"
                  translate="CALC.want_to_register"
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-post-text">
          <p><sup>*</sup>) <span [innerHtml]="'CALC.estimated_hourly_earnings_courier' | translate:calculator"></span></p>
          <p><sup>**</sup>) <span translate="CALC.net_income_info"></span></p>
        </div>
      </div>

    </div>
  </div>
  <!-- SECTION CONTENT END -->

  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>

<div app-info-modal id="fleetFeeModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" [title]="'CALC.fleet_fees' | translate" *ngIf="calculator">
  <div class="fee-info">
    <div class="info-row bg-danger p-a15">
      <h5 translate="">WORKER_TYPES.part_timer</h5>
      <hr>
      <p><strong translate="">CALC.weekly_earnings</strong>:&nbsp;<strong class="text-info">0 - {{ config.courierWorkerThreshold - 1}} €</strong><br>
        <strong translate="">CALC.fleet_fee</strong>:&nbsp;<strong class="text-info">{{ config.courierLowPercentage }} %</strong></p>
    </div>
    <div class="info-row bg-success p-a15">
      <h5 translate="">WORKER_TYPES.worker</h5>
      <hr>
      <p>
        <strong translate="">CALC.weekly_earnings</strong>:&nbsp;<strong class="text-info">{{ config.courierWorkerThreshold }} € <span translate="CALC.and_more"></span></strong><br>
        <strong translate="">CALC.fleet_fee</strong>:&nbsp;<strong class="text-info">{{ config.courierHighPercentage }} %</strong>
      </p>
    </div>
  </div>
</div>
