import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '../modules/messaging/alert.service';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService {

  constructor(
    private alertService: AlertService,
    private router: Router
  ) {
  }

  public handleErrors(response) {
    if (
      response instanceof HttpErrorResponse
      && response.status === 401
    ) {
      const url = this.router.url
      const regex = new RegExp(/^\/(administration|personal-area)/);
      TokenService.getInstance().clearToken();

      if (regex.test(url)) {
        window.location.href = '/?signedOut=1';
      }
    }

    if (
      response instanceof HttpErrorResponse
      && response.error.type
      && (response.error.type === 'errorMessage' || response.error.type === 'systemError')
    ) {
      this.alertError(response.error);
    }
  }

  public alertError(errorMessage) {
    this.alertService.error(errorMessage.message, 15000);
  }
}
