import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';

@Injectable({providedIn: 'root'})
export class AdminCourierEarningsFilterService extends AbstractFilterService {
  constructor() {
    super();
    this.fulltextSearchFields = ['wholeName'];
    this.operations = {phone: 'like', ...this.operations};
  }
}
