<div class="help-block" *ngIf="description && descriptionOnTop">
  {{ description }}
</div>
<label [for]="_ID" *ngIf="label">{{ label }}</label>
<input
  [type]="type"
  [className]="'form-control form-control-' + size"
  [value]="value"
  [id]="_ID"
  [disabled]="_DISABLED"
  [placeholder]="placeholder"
  [required]="required"
  [minLength]="length"
  [maxLength]="length"
  (input)="doChange($event)"
  [attr.inputmode]="'numeric'"
  #input
>
<div class="help-block" *ngIf="errors">
  <span class="text-danger" *ngFor="let error of errors | keyvalue">{{ getErrorByKey(error.key) }}</span>
</div>
<div class="help-block" *ngIf="description && !descriptionOnTop">
  <span class="text-muted">{{ description }}</span>
</div>
<div class="input-tooltip" *ngIf="tooltip">
  {{ tooltip }}
  <span class="input-tooltip-pointer"></span>
</div>
