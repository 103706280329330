import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ContractChangeService {
  public contractChanged = new Subject<any>();

  public contractUpdated() {
    this.contractChanged.next(true);
  }
}
