import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningReportListResponse } from '../model/response/earning-report-list.response';
import { MonthlyDeliveryIncomeResponse } from '../model/response/monthly-delivery-income.response';
import { MonthlyIncomeRequest } from '../model/request/monthly-income.request';

@Injectable()
export class AdminWeeklyIncomeApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchIncomes(page, limit, filter = null) {
    return this.http.get<MonthlyDeliveryIncomeResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/weekly-incomes',
        page,
        limit,
        [{deliveryIncomes: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public pairRegistration(request: MonthlyIncomeRequest) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/weekly-incomes/pair-registration'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }
}
