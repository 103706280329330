import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PlaceOfWorkService {
  private _changedObs = new Subject<string>();

  public change(value) {
    this._changedObs.next(value);
  }

  public changeObs() {
    return this._changedObs;
  }
}
