import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningsResponse } from '../model/response/earnings-response';
import { UserEarningResponse } from '../model/response/user-earning-response';

@Injectable()
export class EarningsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchLast() {
    return this.http.get<UserEarningResponse>(
      this.baseUrl + '/api/cf/personal-area/v2/earnings/last',
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchHistory() {
    return this.http.get<EarningsResponse>(
      this.baseUrl + '/api/cf/personal-area/v2/earnings',
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchEarningByPeriod(period) {
    return this.http.get<EarningsResponse>(
      this.baseUrl + '/api/cf/personal-area/v2/earnings/' + period.replaceAll('-', '.'),
      {headers: this.getAuthHeaders()}
    );
  }
}
