import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningReportListResponse } from '../model/response/earning-report-list.response';
import { DriverMovementListResponse } from '../model/response/driver-movement-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { RecruitmentImportListResponse } from '../model/response/recruitment-import-list.response';

@Injectable()
export class AdminRecruitmentImportApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter = null) {
    return this.http.get<RecruitmentImportListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/recruitment-import',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public saveReport(request: FormData) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/recruitment-import'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  public removeReport(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/recruitment-import/' + id
      , {headers: this.getAuthHeaders()}
    );
  }
}
