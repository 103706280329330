import { Component, Input, OnInit } from '@angular/core';
import { CONTACT } from '../../../../../constants/contact';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CaptchaApi } from '../../../../api-module/api/captcha.api';
import { FormErrorHelper } from '../../../../../helpers/form-error-helper';
import { SendInfoApi } from '../../../../api-module/api/send-info.api';

@Component({
  selector: 'app-footer1',
  templateUrl: './footer1.component.html',
  styleUrls: ['./footer1.component.scss']
})
export class Footer1Component implements OnInit {

  @Input() page: any;
  contact = CONTACT;
  form: FormGroup;
  formErrors: FormErrorHelper;
  errors = {required: 'Pole je povinné', email: 'Vlož valídnu emalovú adresu'};
  captchaErrors = {...this.errors, serverError: 'Platnosť overenia vypršala', required: 'Potvrď, že si človek'};
  modalDisplay = 'none';
  showContinue = false;
  success = false;
  processing = false;

  constructor(
    private captchaAPI: CaptchaApi,
    private infoApi: SendInfoApi,
  ) { }

  ngOnInit(): void {
    this.buildNLForm();
  }

  submit() {
    if (this.form.get('email').invalid) {
      this.formErrors.markControlAsTouched('email');
      return;
    }
    this.modalDisplay = 'block';
  }

  submitConfirm() {
    if (this.form.invalid) {
      this.formErrors.markAsTouched();
      return;
    }

    const request = {
      email: this.form.value.email,
      secInput: this.form.value.secCode
    };
    this.processing = true;
    this.infoApi.send(request).subscribe(r => {
      if (r.code === 200) {
        this.success = true;
        this.form.reset({email: null, secCode: this.form.value.secCode});
      }
      this.processing = false;
    }, e => {
      this.processing = false;
    });
  }

  close() {
    this.modalDisplay = 'none';
    if (this.success) {
      this.success = false;
    }
  }

  getCaptcha(event) {
    if (event.target.checked) {
      this.captchaAPI.fetchCaptcha().subscribe(r => {
        this.form.patchValue({secCode: r.content});
        this.showContinue = true;
      });
      return;
    }
    this.form.patchValue({secCode: ''});
    this.showContinue = false;
  }

  private buildNLForm() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      secCode: new FormControl(null, [Validators.required])
    });
    this.formErrors = new FormErrorHelper(this.form);
  }
}
