import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { MyRecruitsResponse } from '../model/response/my-recruits-response';
import { RecruitmentBonusEarningListResponse } from '../model/response/recruitment-bonus-earning-list.response';

@Injectable()
export class PaRecruitingBonusApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchMyRecruited(
    page,
    limit
  ) {
    return this.http.get<MyRecruitsResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/personal-area/my-recruited',
        page,
        limit,
        [{id: 'DESC'}]
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchMyTotal() {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/personal-area/recruitment-bonus/payments',
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchMyEarnings(page, limit) {
    return this.http.get<RecruitmentBonusEarningListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/personal-area/recruitment-bonus/earnings',
        page,
        limit,
        [{periodFrom: 'DESC'}]
      ),
      {headers: this.getAuthHeaders()}
    );
  }
}
