import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PAGE_LANGUAGES } from '../constants/page-languages';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

@Injectable()
export class LanguageStateService {
  languageState: BehaviorSubject<string>;
  availableLanguages: BehaviorSubject<any>;

  constructor() {
    const langCode = StorageService.get('language') || environment.defaultLanguage;
    this.languageState = new BehaviorSubject(langCode);
    this.availableLanguages =  new BehaviorSubject([PAGE_LANGUAGES[langCode.toUpperCase()]]);
  }

  storeLang(lang: string) {
    StorageService.set('language', lang);
    this.languageState.next(lang);
  }

  getLang() {
    return StorageService.get('language');
  }

  storeAvailableLanguages(languages: any[]) {
    StorageService.set('availableLanguages', JSON.stringify(languages));
    this.availableLanguages.next(languages);
  }

  getAvailableLanguages() {
    return JSON.parse(StorageService.get('availableLanguages'));
  }
}
