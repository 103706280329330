<header class="site-header header-style-1">
  <div class="top-bar bg-gray">
    <div class="container">
      <div class="row">
        <div class="mt-topbar-left clearfix">
          <ul class="list-unstyled e-p-bx pull-right">
            <li><i class="fa fa-envelope"></i> {{ contact.emailName }}<!---->@<!---->{{ contact.emailDomain }}</li>
            <li><i class="fa fa-phone"></i> {{contact.phone }}</li>
            <!--                        <li><i class="fa fa-clock-o"></i>Sun-Sat 9.45 am</li>-->
          </ul>
        </div>

        <div class="mt-topbar-right clearfix" *ngIf="!loggedUser">
          <div class="appint-btn"><a routerLink="/join-us" class="site-button">{{ 'HEADER.join_us' | translate }}</a>
          </div>
        </div>

        <div class="mt-topbar-right clearfix" *ngIf="loggedUser">
          <div class="appint-btn">
            <a [routerLink]="loggedUser.role === 'user' ? '/personal-area/registration-state' : '/administration'" class="site-button">
              <i class="fa fa-user"></i>&nbsp;
              <span>{{ loggedUser.first_name }} {{ loggedUser.last_name }}</span>
            </a>
          </div>
        </div>

        <div class="mt-topbar-right">
          <app-language-switch></app-language-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="sticky-header main-bar-wraper">
    <div class="main-bar bg-white">
      <div class="container" [ngClass]="{'is-fixed': toggle}">
        <div class="logo-header">
          <div class="logo-header-inner logo-header-one">
            <a routerLink="/">
              <img src="assets/images/coffee-taxi-medium-logo.png" alt=""/>
            </a>
          </div>
        </div>
        <!-- NAV Toggle Button -->
        <button
          (click)="toggleMenu()"
          type="button" class="navbar-toggle collapsed site-button green button-sm"
        >
          <i class="fa fa-bars"></i> Menu
<!--          <span class="sr-only">Toggle navigation</span>-->
<!--          <span class="icon-bar"></span>-->
<!--          <span class="icon-bar"></span>-->
<!--          <span class="icon-bar"></span>-->
        </button>
        <!-- ETRA Nav -->
        <div class="extra-nav">
          <!--                    <div class="extra-cell">-->
          <!--                        <a id="#search">-->
          <!--                            <i class="fa fa-search"></i>-->
          <!--                        </a>-->
          <!--                    </div>-->
          <div class="extra-cell">
            <a href="javascript:void(0);" class="contact-slide-show"><i
              class="fa fa-angle-left arrow-animation"></i></a>
          </div>
        </div>
        <!-- ETRA Nav -->

        <!-- Contact Nav -->
        <div class="contact-slide-hide " style="background-image:url(assets/images/background/bg-5.png)">
          <div class="contact-nav">
            <a href="javascript:void(0);" class="contact_close">&times;</a>
            <div class="contact-nav-form p-a30">
              <div class="contact-info   m-b30">

                <div class="mt-icon-box-wraper center p-b30">
                  <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-phone"></i></div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500" translate="">Phone Number</h5>
                    <p>{{ contact.phone }}</p>
                  </div>
                </div>

                <div class="mt-icon-box-wraper center p-b30">
                  <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-envelope"></i></div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500">Email</h5>
                    <p>{{ contact.emailName }}<!---->@<!---->{{ contact.emailDomain }}</p>
                  </div>
                </div>

                <div class="mt-icon-box-wraper center p-b30">
                  <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-map-marker"></i>
                  </div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500" translate="">Post Address</h5>
                    <p [innerHtml]="contact.postAddress"></p>
                  </div>
                </div>
              </div>
              <div class="full-social-bg">
                <ul>
                  <li><a [href]="contact.facebookUrl" class="facebook" target="_blank"><i
                    class="fa fa-facebook"></i></a></li>
                  <li><a [href]="contact.linkedInUrl" class="linkedin" target="_blank"><i
                    class="fa fa-linkedin"></i></a></li>
<!--                  <li><a href="https://www.google.com" class="google"><i class="fa fa-google"></i></a>-->
<!--                  </li>-->
<!--                  <li><a href="https://www.instagram.com" class="instagram"><i-->
<!--                    class="fa fa-instagram"></i></a></li>-->
<!--                  <li><a href="https://www.tumblr.com" class="tumblr"><i class="fa fa-tumblr"></i></a>-->
<!--                  </li>-->
<!--                  <li><a href="https://twitter.com" class="twitter"><i class="fa fa-twitter"></i></a>-->
<!--                  </li>-->
<!--                  <li><a href="https://www.youtube.com" class="youtube"><i-->
<!--                    class="fa fa-youtube"></i></a></li>-->
                </ul>
              </div>
              <div class="text-center">
                <div class="font-weight-600 m-t10 mt-b20 font-12" [innerHtml]="contact.copyRightShort"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- SITE Search -->
        <div id="search">
<!--          <span class="close"></span>-->
<!--          <form role="search" id="searchform" action="/search" method="get" class="radius-xl">-->
<!--            <div class="input-group">-->
<!--              <input value="" name="q" type="search" placeholder="Type to search"/>-->
<!--              <span class="input-group-btn"><button type="button" class="search-btn"><i-->
<!--                class="fa fa-search arrow-animation"></i></button></span>-->
<!--            </div>-->
<!--          </form>-->
        </div>
        <!-- MAIN Vav -->
        <div class="header-nav navbar-collapse collapse">
          <ul class=" nav navbar-nav" *ngIf="menu">
            <ng-template ngFor let-item [ngForOf]="menu">
              <li [ngClass]="{active: false}">
                <ng-template [ngIf]="item.subMenu">
                  <a href="javascript:void(0);">{{ item.title }}</a>
                  <ul class="sub-menu">
                    <li *ngFor="let subItem of item.subMenu">
                      <ng-template [ngIf]="!subItem.subMenu">
                        <a [href]="subItem.url" (click)="navigateTo(subItem.url)">{{ subItem.title }}</a>
                      </ng-template>
                      <ng-template [ngIf]="subItem.subMenu">
                        <a href="javascript:void(0);">{{ subItem.title }}</a>
                        <ul class="sub-menu has-child">
                          <li *ngFor="let subSubItem of subItem.subMenu">
                            <a [href]="subSubItem.url" (click)="navigateTo(subSubItem.url)">{{ subSubItem.title }}</a>
                          </li>
                        </ul>
                      </ng-template>
                    </li>
                  </ul>
                </ng-template>

                <ng-template [ngIf]="!item.subMenu">
                  <a [href]="item.url" (click)="navigateTo(item.url)">{{ item.title }}</a>
                </ng-template>
              </li>
            </ng-template>
          </ul>
        </div>

      </div>
    </div>
  </div>
</header>
