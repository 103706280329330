import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { RegistrationDetailResponse } from '../model/response/registration-detail.response';
import { RegistrationListResponse } from '../model/response/registration-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { AdminNewRegistrationRequest } from '../model/request/admin-new-registration-request';
import { IndividualFeesRequest } from '../model/request/individual-fees.request';

@Injectable()
export class AdminIndividualFeeApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public saveIndividualFees(request: IndividualFeesRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-fees`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public updateIndividualFees(id: number, request: IndividualFeesRequest) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-fees/${id}`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public deleteIndividualFees(id: number) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/individual-fees/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }
}
