import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calculator } from '../../modules/api-module/model/calculator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '../../helpers/form-error-helper';
import { CaptchaApi } from '../../modules/api-module/api/captcha.api';
import { RegistrationApi } from '../../modules/api-module/api/registration.api';
import { RegistrationRequest } from '../../modules/api-module/model/request/registration-request';
import { HttpErrorResponse } from '@angular/common/http';
import { validatePhoneNumber } from '../../modules/forms/validators/phone-number.validator';
import { StorageService } from '../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../../services/configuration.service';
import { TempRegistrationApi } from '../../modules/api-module/api/temp-registration.api';
import { validateFileSize } from '../../modules/forms/validators/file-size.validator';
import { validateFileTypes } from '../../modules/forms/validators/file-types.validator';
import { WORKING_PLACES } from '../../constants/working-places';
import {LanguageStateService} from '../../services/language-state.service';
import {Subscription} from 'rxjs';
import {SelectItemInterface} from '../../modules/forms/models/select-item.interface';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration.component.html',
  styleUrls: [
    './registration.component.scss'
  ]
})
export class RegistrationComponent implements OnInit, OnDestroy {

  private days = [];
  private hours = 0;

  public banner = {
    image: "assets/images/banner/banner-reg.jpg",
    title: "REG.banner_title",
    page: "REG.banner_page"
  }

  public form: FormGroup;
  public processing = false;

  public boltStandingOptions = [
    {label: 'Pracant', value: 'WORKER'},
    {label: 'Makač', value: 'WORKAHOLIC'},
    {label: 'Brigádnik', value: 'PART_TIMER'},
  ];

  public workPlaceOptions = Object.keys(WORKING_PLACES).map(key => {
    return {value: key, label: WORKING_PLACES[key]};
  });

  public fee = '0.00';
  public calculator: Calculator;
  formErrors: FormErrorHelper;
  errorMessages = {
    required: 'Toto pole je povinné',
    minlength: 'Pole nespĺňa minmálnu dĺžku',
    maxlength: 'Pole prekročilo maximálnu dĺžku',
    pattern: 'Pole nespĺňa validačné pravidlá',
    email: 'Vlož validný email'
  };
  emailErrors = {...this.errorMessages, serverError: 'Email už existuje'};
  captchaErrors = {...this.errorMessages, serverError: 'Platnosť overenia vypršala', required: 'Potvrď, že si človek'};
  phoneErrors = {...this.errorMessages, phoneNumber: 'Vlož platné telefónne číslo'};
  imageErrors = {
    ...this.errorMessages,
    validateFileSize: 'Obrázok môže byť max. 5MB veľký, zmenši si nastavenie fotoaparátu, alebo zmenši obrázok.',
    validateFileTypes: 'Obrázok môže byť vo formáte JPG, JPEG, PNG a GIF.'
  };
  agreementRouteLink = '';
  sourceOptionsByLang = {
    sk: [
      {label: 'Facebook', value: 'Facebook'},
      {label: 'Bazoš', value: 'Bazos'},
      {label: 'Odporučený inou osobou', value: 'Recommended by'},
      {label: 'Iné', value: 'Other'},
    ],
    en: [
      {label: 'Facebook', value: 'Facebook'},
      {label: 'Bazoš', value: 'Bazos'},
      {label: 'Recommended by a person', value: 'Recommended by'},
      {label: 'Other', value: 'Other'},
    ],
    ru: [
      {label: 'Facebook', value: 'Facebook'},
      {label: 'Bazoš', value: 'Bazos'},
      {label: 'Pекомендуемые', value: 'Recommended by'},
      {label: 'Другой', value: 'Other'},
    ]
  };

  sourceOptions = [];

  langSub: Subscription;
  showSourcePerson = false;
  hideSource = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private captchaAPI: CaptchaApi,
    private registrationAPI: RegistrationApi,
    private tempRegistrationAPI: TempRegistrationApi,
    private translate: TranslateService,
    private languageService: LanguageStateService
  ) {
  }

  ngOnInit(): void {
    const lang = this.languageService.getLang();
    this.sourceOptions = this.sourceOptionsByLang[lang];

    this.langSub = this.languageService.languageState.subscribe(l => {
      this.sourceOptions = this.sourceOptionsByLang[l];
    });

    this.agreementRouteLink = ConfigurationService.get('agreementRouteLink');
    this.form = this.buildForm();
    this.formErrors = new FormErrorHelper(this.form);
    this.route.queryParams.subscribe(p => {
      if (p['pref']) {
        this.form = this.buildForm(p['pref']);
        this.formErrors = new FormErrorHelper(this.form);
        return;
      }
      else if (p['prereg']) {
        StorageService.set('preregToken', p['prereg']);
        this.tempRegistrationAPI.getTempRegistration(p['prereg']).subscribe(r => {
          if (r.content) {
            this.hideSource = true;
            this.form = this.buildFilledForm(p['prereg'], r.content);
            this.formErrors = new FormErrorHelper(this.form);
          }
        });
        return;
      }
      else if (StorageService.get('preregToken')) {
        const token = StorageService.get('preregToken');
        this.tempRegistrationAPI.getTempRegistration(token).subscribe(r => {
          if (r.content) {
            this.hideSource = true;
            this.form = this.buildFilledForm(token, r.content);
            this.formErrors = new FormErrorHelper(this.form);
          } else {
            StorageService.remove('preregToken');
          }
        });
        return;
      }
    });
  }

  ngOnDestroy(): void {
  }

  submit() {

    if (this.form.invalid) {
      this.formErrors.markAsTouched();
      return;
    }

    this.processing = true;
    this.registrationAPI.register(this.prepareRequest()).subscribe(
      r => {
        if (r.code === 200) {
          StorageService.set('emailForPhoneVerification', this.form.value.email);
          StorageService.remove('preregToken');
          this.router.navigate(
            ['/registration/phone-verification']
          )
        }
        this.processing = false;
      },
      e => {
        if (e instanceof HttpErrorResponse
          && e.status === 422
          && e.error.type === 'validationError'
          && e.error.errors[0] === 'Email is registered'
        ) {
          const email = this.form.get('email');
          this.translate.get('Email is registered').subscribe(t => {
            email.setErrors({
              serverError: t
            });
          });
        }

        if (e instanceof HttpErrorResponse
          && e.status === 422
          && e.error.type === 'validationError'
          && e.error.errors[0] === 'Phone is registered'
        ) {
          const phoneNumber = this.form.get('phoneNumber');
          this.translate.get('Phone is registered').subscribe(t => {
            phoneNumber.setErrors({
              serverError: t
            });
          });
        }

        if (
          e instanceof HttpErrorResponse
          && e.status === 422
          && (e.error.message === 'Invalid Captcha' || e.error.message === 'Expired Captcha'))
        {
          const input = this.form.get('secInput');
          input.setErrors({
            serverError: e.error.message
          });
          this.form.patchValue({secInput: false});
        }
        this.processing = false;
      }
    );
  }

  togglePerson(item: SelectItemInterface) {
    this.showSourcePerson = item.value === 'Recommended by';
  }

  getCaptcha(event) {
    if (event.target.checked) {
      this.processing = true;
      this.captchaAPI.fetchCaptcha().subscribe(r => {
        this.form.patchValue({secInput: r.content});
        this.processing = false;
      });
      return;
    }
    this.form.patchValue({secInput: ''});
  }

  private buildForm(prefer = null) {
    return new FormGroup({
      prefer: new FormControl(prefer),
      prereg: new FormControl(null),
      source: new FormControl(null),
      sourcePerson: new FormControl(null),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required, validatePhoneNumber]),
      profileImage: new FormControl(null, [validateFileSize(5128000), validateFileTypes(['jpg', 'jpeg', 'png', 'gif'])]),
      boltStanding: new FormControl(this.boltStandingOptions[0], [Validators.required]),
      workPlace: new FormControl(null, [Validators.required]),
      agreement: new FormControl(false, [Validators.requiredTrue]),
      secInput: new FormControl('', [Validators.required]),
    });
  }

  private buildFilledForm(token, data) {
    return new FormGroup({
      prefer: new FormControl(null),
      prereg: new FormControl(token),
      source: new FormControl('Bolt Food Lead'),
      sourcePerson: new FormControl(null),
      firstName: new FormControl(data.firstName, [Validators.required]),
      lastName: new FormControl(data.lastName, [Validators.required]),
      email: new FormControl(data.email, [Validators.required, Validators.email]),
      phoneNumber: new FormControl(data.phoneNumber, [Validators.required, validatePhoneNumber]),
      profileImage: new FormControl(null, [validateFileSize(5128000), validateFileTypes(['jpg', 'jpeg', 'png', 'gif'])]),
      boltStanding: new FormControl(this.boltStandingOptions[0], [Validators.required]),
      workPlace: new FormControl(null, [Validators.required]),
      agreement: new FormControl(false, [Validators.requiredTrue]),
      secInput: new FormControl('', [Validators.required]),
    });
  }

  private prepareRequest()
  {
    const request: RegistrationRequest = {...this.form.value};
    request.boltStanding = this.form.value.boltStanding.value;
    request.workPlace = this.form.value.workPlace.value;
    request.source = this.form.value.source && this.form.value.source.value ? this.form.value.source.value : null;

    if (request.source) {
      request.source += this.form.value.sourcePerson ? ' ' + this.form.value.sourcePerson : '';
    }


    return request;
  }
}
