import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class MenuInvalidationService {
  public menuReload = new Subject();

  public reloadMenu() {
    this.menuReload.next();
  }
}
