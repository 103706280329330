<label [for]="_ID" *ngIf="label" [ngClass]="{required: required}">
  {{ label }}
</label>
<div class="help-block" *ngIf="description && descriptionOnTop">
  <span [innerHtml]="description" class="text-info"></span>
</div>
<ng-select
  [items]="options"
  [placeholder]="placeholder"
  [className]="'ng-select form-select form-select-' + size"
  [id]="_ID"
  [(ngModel)]="_VALUE"
  [disabled]="_DISABLED"
  (change)="selected($event)"
  [clearable]="clearable"
  [searchable]="searchable"
>
  <ng-template ng-option-tmp let-item="item" let-index="index">
<!--    <img src="assets/images/selected.svg" alt="sel" class="selected">-->
    <span class="select-label" [title]="item.label">{{item.label}}</span>
  </ng-template>
</ng-select>

<div class="help-block" *ngIf="errors">
  <span class="text-danger" *ngFor="let error of errors | keyvalue">{{ getErrorByKey(error.key) }}</span>
</div>

<div class="help-block" *ngIf="description && !descriptionOnTop">
  <span [innerHtml]="description" class="text-info"></span>
</div>

<div class="input-tooltip" *ngIf="tooltip">
  {{ tooltip }}
  <span class="input-tooltip-pointer"></span>
</div>
