import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({providedIn: 'root'})
export class LoggedOutGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (TokenService.getInstance().get()) {
      window.location.href = '/personal-area/registration-state';
      return of(false);
    }

    return of(true);
  }
}
