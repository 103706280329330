import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageInterface } from './language.interface';
import { LanguageStateService } from '../../../../services/language-state.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html'
})
export class LanguageSwitchComponent implements OnInit {
  selected: string;
  languages: LanguageInterface[] = [];

  constructor(
    private translate: TranslateService,
    private languageStateService: LanguageStateService
  ) {

  }

  ngOnInit() {
    this.selected = this.languageStateService.getLang();
    this.languages = this.languageStateService.getAvailableLanguages();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.languageStateService.storeLang(lang);
    this.selected = lang;
  }
}
