import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { ApiTokenService } from '../services/api-token-service';
import { AbstractApiController } from './abstract-api-controller';
import { Observable } from 'rxjs';
import { BlockResponseInterface } from '../model/response/block-response.interface';

@Injectable()
export class BlockApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchBlock(id) {
    return this.http.get<BlockResponseInterface>(this.baseUrl + '/api/blocks/' + id + '?XDEBUG_SESSION_START=coffee');
  }
}
