import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { JoinUsComponent } from './pages/join-us/join-us.component';
import { CalculatorTaxiComponent } from './pages/calculator-taxi/calculator-taxi.component';
import { CalculatorCourierComponent } from './pages/calculator-courier/calculator-courier.component';
import { PostImageComponent } from './pages/article/post-image/post-image.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LoggedOutGuard } from './services/logged-out.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { PhoneVerificationComponent } from './pages/phone-verification/phone-verification.component';
import { ManualPreviewComponent } from './pages/manual-preview/manual-preview.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AuthAdminGuard } from './services/auth-admin.guard';
import { PreRegistrationComponent } from './pages/pre-registration/pre-registration.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';


const routes: Routes = [
  { path: 'article/:id', component: PostImageComponent },
  { path: 'calculator/taxi', component: CalculatorTaxiComponent },
  { path: 'calculator/courier', component: CalculatorCourierComponent },
  { path: 'courier-manual', component: ManualPreviewComponent },
  {
    path: 'personal-area',
    loadChildren: () => import('src/app/pages/personal-area/personal-area.module').then(m => m.PersonalAreaModule)
  },
  {
    path: 'registration/phone-verification',
    component: PhoneVerificationComponent,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'pre-registration',
    component: PreRegistrationComponent,
    canActivate: [LoggedOutGuard]
  },
  { path: 'join-us', component: JoinUsComponent },
  {
    path: 'administration',
    canActivate: [AuthAdminGuard],
    loadChildren: () => import('src/app/pages/admin/admin.module').then(m => m.AdminModule)
  },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: '/personal-area/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
