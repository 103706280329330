import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { SalaryListResponse } from '../model/response/salary-list.response';
import { SalaryDetailResponse } from '../model/response/salary-detail.response';
import { FileUriResponse } from '../model/response/file-uri.response';

@Injectable()
export class SalariesApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchSalaries(page, limit, filter = null) {
    return this.http.get<SalaryListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/personal-area/salaries',
        page,
        limit,
        [{month: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(year, month) {
    return this.http.get<SalaryDetailResponse>(
      this.baseUrl + '/api/cf/personal-area/salary',
      {headers: this.getAuthHeaders(), params: {year, month}}
    );
  }

  public getSalaryPreview(period) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/personal-area/salary/preview?year=' + period.year + '&month=' + period.month
      ,{headers: this.getAuthHeaders()}
    );
  }

  public generateReport(id) {
    return this.http.get<FileUriResponse>(
      this.baseUrl + `/api/cf/personal-area/salary/${id}/generate-report`
      ,{headers: this.getAuthHeaders()}
    );
  }
}
