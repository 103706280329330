import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralMessageComponent } from './general-message/general-message.component';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert.service';
import { MessagingService } from './messaging.service';



@NgModule({
  declarations: [
    GeneralMessageComponent,
    AlertComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GeneralMessageComponent,
    AlertComponent
  ],
  providers: [
  ]
})
export class MessagingModule { }
