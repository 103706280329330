import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningReportListResponse } from '../model/response/earning-report-list.response';

@Injectable()
export class AdminUberEarningsReportApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchEarnings(page, limit, filter = null) {
    return this.http.get<EarningReportListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/v2/uber-earning-reports',
        page,
        limit,
        [{periodTo: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveReport(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report'
      , request
      , {headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/' + id
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public proceed(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/' + id + '/proceed'
      , {}
      ,{headers: this.getAuthHeaders()}
    );
  }

  public approve(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/' + id + '/approve'
      , {}
      ,{headers: this.getAuthHeaders()}
    );
  }

  public upload(id, formData) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/' + id + '/import-report'
      , formData
      ,{headers: this.getAuthHeaders()}
    );
  }

  public check(formData) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/v2/uber-earning-report/check-report'
      , formData
      ,{headers: this.getAuthHeaders()}
    );
  }
}
