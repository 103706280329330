<div *ngIf="configLoaded">
<div class="page-wraper" *ngIf="configLoaded">
  <!-- HEADER START -->
  <app-header1 [loggedUser]="loggedUser"></app-header1>

  <router-outlet></router-outlet>
</div>

<app-loader></app-loader>

<!-- BUTTON TOP START -->
<button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>

<app-alert></app-alert>
</div>
