import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageModel } from './message-model';

@Injectable({providedIn: 'root'})
export class AlertService {

  private messageAdded = new Subject<MessageModel>();

  constructor() {
  }

  public onMessageAdded(): Subject<MessageModel> {
    return this.messageAdded;
  }

  public success(message: string, time = 10000, closer = true) {
    this.messageAdded.next(new MessageModel('success', message, time, closer));
  }

  public error(message: string, time = 15000, closer = true) {
    this.messageAdded.next(new MessageModel('danger', message, time, closer));
  }

  public warning(message: string, time = 10000, closer = true) {
    this.messageAdded.next(new MessageModel('warning', message, time, closer));
  }

  public info(message: string, time = 10000, closer = true) {
    this.messageAdded.next(new MessageModel('info', message, time, closer));
  }

  public asyncSuccess(message: string, time = 10000, closer = true) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.messageAdded.next(new MessageModel('success', message, time, closer));
        resolve(true);
      }, 200);
    });
  }

  public asyncError(message: string, time = 15000, closer = true) {
    return new Promise((r, er) => {
      setTimeout(() => {
        this.messageAdded.next(new MessageModel('error', message, time, closer));
        r(true);
      }, 200);
    });
  }

  public asyncWarning(message: string, time = 10000, closer = true) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.messageAdded.next(new MessageModel('warning', message, time, closer));
        resolve(true);
      }, 200);
    });
  }

  public asyncInfo(message: string, time = 10000, closer = true) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.messageAdded.next(new MessageModel('info', message, time, closer));
        resolve(true);
      }, 200);
    });
  }
}
