import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { AuthApi } from '../modules/api-module/api/auth.api';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthAdminGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private authAPI: AuthApi
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!TokenService.getInstance().get()) {
      this.authService.redirectToLogin();
      return of(false);
    }

    return this.authAPI.getProfile(TokenService.getInstance().get())
      .pipe(
        map(r => {
          this.authService.setProfile(r.content);
          return r.code === 200 && !!r.content && (r.content.role === 'admin' || r.content.role === 'editor');
        }),
        catchError((err) => {
          this.authService.redirectToLogin();
          return of(false);
        })
      )
  }
}
