import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class BackendTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private baseUrl,
    private endpoint
  ) {
  }
  getTranslation(lang: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/${lang}/${this.endpoint}`
    ).pipe(
      map(r => r.data[lang])
    );
  }
}
