import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { LanguageStateService } from '../../../services/language-state.service';
import { CourierEarningResponse } from '../model/response/courier-earning-response';
import { CourierEarningsListResponse } from '../model/response/courier-earnings-list-response';

@Injectable()
export class CourierEarningsApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public getLastEarnings() {
    return this.http.get<CourierEarningResponse>(
      this.baseUrl + `/api/cf/personal-area/courier-earnings/last`,
      {headers: this.getAuthHeaders()}
    );
  }

  public getEarnings(page, limit, filter= {}) {
    return this.http.get<CourierEarningsListResponse>(
      this.prepareListRequest(
        this.baseUrl + `/api/cf/personal-area/courier-earnings`,
        page,
        limit,
        [{periodTo: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }
}
