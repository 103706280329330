import { FormArray, FormGroup } from '@angular/forms';

export class FormErrorHelper {
  constructor(
    public form: FormGroup
  ) {
  }

  showErrorsFor(elem): boolean {
    return elem.invalid && (elem.dirty || elem.touched);
  }

  getErrorsFor(elem: string, index?: number, subElement?: string) {
    let el = this.form.get(elem);
    if (index !== undefined && subElement !== undefined) {
      // @ts-ignore
      el = el.controls[index].get(subElement);
    }
    return this.showErrorsFor(el) ? el.errors : null;
  }

  fireBackendError(field, message) {
    this.form.controls[field].setErrors({backend: true, message: message});
  }

  handleBackendErrors(errors: {field: string, messages: string[]}[]) {
    errors.forEach(error => {
      this.fireBackendError(error.field, error.messages.join(', '));
    });
  }

  markAsTouched() {
    // tslint:disable-next-line:forin
    for (const index in this.form.controls) {
      this.markControlAsTouched(index);
    }
  }

  markControlAsTouched(index) {
    const ctrl = this.form.controls[index];

    if (ctrl instanceof FormArray) {
      this.markAsTouchedSubs(ctrl.controls);
      return;
    }

    if (ctrl.invalid) {
      ctrl.markAsTouched();
    }
  }

  private markAsTouchedSubs(controls) {
    controls.forEach((formGroup, i) => {
      Object.keys(formGroup.controls).forEach(index => {
        const ctrl = formGroup.controls[index];

        if (ctrl.invalid) {
          ctrl.markAsTouched();
        }
      });
    });
  }
}
