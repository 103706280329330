<!-- CONTENT START -->
<div class="page-content">

  <!-- INNER PAGE BANNER -->
  <app-banner [data]="banner"></app-banner>
  <!-- INNER PAGE BANNER END -->

  <!-- SECTION CONTENTG START -->
  <div class="section-full p-tb80 inner-page-padding">
    <!-- LOCATION BLOCK-->
    <div class="container">

      <!-- GOOGLE MAP & CONTACT FORM -->
      <div class="section-content">
        <!-- CONTACT FORM-->
        <div class="row">
          <div class="col-md-8 col-sm-6">
            <form class="contact-form" [formGroup]="form" (ngSubmit)="submit()">
              <div class="contact-one m-b30">

                <!-- TITLE START -->
                <div class="section-head">
                  <div class="mt-separator-outer separator-left">
                    <div class="mt-separator">
                      <h2 class="text-uppercase sep-line-one" [innerHtml]="'CONTACT.page_header' | translate"></h2>
                    </div>
                  </div>
                </div>
                <!-- TITLE END -->

                <div
                  text-input
                  formControlName="name"
                  [placeholder]="'CONTACT.name' | translate"
                  [errors]="this.formErrors.getErrorsFor('name')"
                  [messages]="errors"
                ></div>

                <div
                  text-input
                  formControlName="email"
                  [placeholder]="'CONTACT.email' | translate"
                  [errors]="formErrors.getErrorsFor('email')"
                  [messages]="errors"
                ></div>

                <div
                  textarea
                  formControlName="message"
                  [placeholder]="'CONTACT.message' | translate"
                  [errors]="formErrors.getErrorsFor('message')"
                  [messages]="errors"
                ></div>

                <div
                  form-checkbox
                  [label]="'REG.not_robot' | translate"
                  id="captcha"
                  size="sm"
                  [messages]="captchaErrors"
                  [errors]="formErrors.getErrorsFor('secInput')"
                  (change)="getCaptcha($event)"
                ></div>

                <div class="text-right">
                  <button
                    [disabled]="processing"
                    name="submit"
                    type="submit" value="Submit" class="site-button btn-effect"
                    translate="CONTACT.send"
                  ></button>
                </div>

                <div class="m-t30" *ngIf="success">
                  <div class="alert alert-success" translate="CONTACT.success_alert"></div>
                </div>

              </div>
            </form>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="contact-info m-b30">
              <!-- TITLE START -->
              <div class="section-head">
                <div class="mt-separator-outer separator-left">
                  <div class="mt-separator">
                    <h2 class="text-uppercase sep-line-one" [innerHtml]="'CONTACT.contact_info_heading' | translate"></h2>
                  </div>
                </div>
              </div>
              <!-- TITLE END -->
              <div class="bg-dark p-a20 text-white">
                <div class="mt-icon-box-wraper left p-b40">
                  <div class="icon-xs"><i class="fa fa-phone"></i></div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500" translate="CONTACT.phoneNumber"></h5>
                    <p>{{ contacts.phone }}</p>
                  </div>
                </div>

                <div class="mt-icon-box-wraper left p-b40">
                  <div class="icon-xs"><i class="fa fa-envelope"></i></div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500" translate="CONTACT.email_address"></h5>
                    <p>{{ contacts.emailName}}<!---->@<!---->{{contacts.emailDomain}}</p>
                  </div>
                </div>

                <div class="mt-icon-box-wraper left">
                  <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
                  <div class="icon-content">
                    <h5 class="m-t0 font-weight-500" translate="CONTACT.post_address"></h5>
                    <p [innerHtml]="contacts.postAddress"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="gmap-outline">-->
<!--        &lt;!&ndash; <div id="gmap_canvas2" class="google-map"></div> &ndash;&gt;-->
<!--        <google-map  height="500px" width="100%"></google-map>-->
<!--      </div>-->
    </div>
  </div>
  <!-- SECTION CONTENT END -->

</div>
<!-- CONTENT END -->

<!-- FOOTER START -->
<app-footer1></app-footer1>
<!-- FOOTER END -->

<app-loader></app-loader>
