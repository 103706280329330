import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { EarningsResponseV2 } from '../model/response/earnings-response-v2';

@Injectable()
export class AdminEarningsV2Api extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchEarnings(period, ownerCode) {
    return this.http.get<EarningsResponseV2>(
      this.baseUrl + '/api/cf/administration/v2/earnings/' + period.replaceAll('-', '.') + (ownerCode ? `?owner=${ownerCode}` : ''),
      {headers: this.getAuthHeaders()}
    );
  }

  // public fetchEarningsByMonthAndYear(month, year, ownerCode) {
  //   return this.http.get<EarningsResponse>(
  //     this.baseUrl + `/api/cf/administration/earnings/monthly-preview?y=${year}&m=${month}` + (ownerCode ? `&owner=${ownerCode}` : ''),
  //     {headers: this.getAuthHeaders()}
  //   );
  // }
  //
  // public fetchEarningsByWeekRange(from, to, ownerCode) {
  //   return this.http.get<EarningsResponse>(
  //     this.baseUrl + `/api/cf/administration/earnings/weekly-preview?from=${from}&to=${to}` + (ownerCode ? `&owner=${ownerCode}` : ''),
  //     {headers: this.getAuthHeaders()}
  //   );
  // }
  //
  public generateInvoicePayments(from, to) {
    return this.http.post<any>(
      this.baseUrl + `/api/cf/administration/v2/earnings/generate-paid-advances-for-invoices`,
      {periodFrom: from, periodTo: to},
      {headers: this.getAuthHeaders()}
    );
  }
}
