import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { MyRecruitsResponse } from '../model/response/my-recruits-response';
import { RecruitmentBonusEarningListResponse } from '../model/response/recruitment-bonus-earning-list.response';

@Injectable()
export class AdminBuyingSocietyApiService extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchDetail(regId) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/administration/registration/{id}/buying-society'.replace('{id}', regId),
      {headers: this.getAuthHeaders()}
    );
  }

  public create(request: {registrationId: number}) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration/buying-society',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
