export const environment = {
  production: true,
  apiUrl: 'https://test-be.coffee-taxi.sk',
  defaultLanguage: 'sk',
  xdebug: false,
  xdebugKey: null,
  appId: 'c9a6761c-d2b9-44d1-86c8-b5deb277a921',
  loginUrl: (lang) => `https://test-be.coffee-taxi.sk/${lang}/cf/login?appId=c9a6761c-d2b9-44d1-86c8-b5deb277a921&returnUrl=`
    + encodeURIComponent('https://test-drivers.coffee-taxi.sk/auth'),
};
