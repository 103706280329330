import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { LogoutApi } from '../../modules/api-module/api/logout.api';
import { Subscription } from 'rxjs';
import { MenuInvalidationService } from '../../services/menu-invalidation.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout-component',
  template: ''
})
export class LogoutComponent implements OnInit, OnDestroy {

  querySub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logoutAPI: LogoutApi,
    private menuInvalidation: MenuInvalidationService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.querySub = this.route.queryParams.subscribe(p => {
      const dest = '/' + (p['destination'] || '');
      this.logout(dest);
    });
  }

  ngOnDestroy() {
    this.querySub.unsubscribe();
  }

  private logout(destination) {
    this.logoutAPI.logout().subscribe(
      r => {
        TokenService.getInstance().clearToken();
        this.router.navigate([destination]).then(() => {
          this.menuInvalidation.reloadMenu();
          this.authService.setProfile(null);
        });
      },
      e => {
        console.warn(e);
        TokenService.getInstance().clearToken();
        this.router.navigate(['/']).then(() => {
          this.menuInvalidation.reloadMenu();
          this.authService.setProfile(null);
        });
      }
    )
  }
}
