import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { RegistrationDetailResponse } from '../model/response/registration-detail.response';
import { RegistrationListResponse } from '../model/response/registration-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { AdminNewRegistrationRequest } from '../model/request/admin-new-registration-request';
import { IndividualFeesRequest } from '../model/request/individual-fees.request';

@Injectable()
export class AdminRegistrationApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchRegistrations(filter, page, limit) {
    return this.http.get<RegistrationListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/registrations',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id, contractPeriodFrom = null, contractPeriodTo = null) {
    let query = '';
    if (contractPeriodFrom && contractPeriodTo) {
      query = `?pfrom=${contractPeriodFrom}&pto=${contractPeriodTo}`;
    }
    return this.http.get<RegistrationDetailResponse>(
      this.baseUrl + '/api/cf/administration/registrations/' + id + query,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveNew(request: AdminNewRegistrationRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registrations',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public requestDocuments(id: number, request: {requiredDocuments: string[]}) {
    return this.http.post<any>(
      this.baseUrl + `/api/cf/administration/registration/${id}/request-documents`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveIndividualFees(id: number, request: IndividualFeesRequest) {
    return this.http.post<any>(
      this.baseUrl + `/api/cf/administration/registration/${id}/individual-fees`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public generateAccessToken(id: number) {
    return this.http.get<any>(
      this.baseUrl + `/api/cf/administration/registration/${id}/generate-token`,
      {headers: this.getAuthHeaders()}
    );
  }

  public pairUber(id: number, request: {uberDriverUUID: string}) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registrations/'+id+'/pair-uber',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
