import { Component, EventEmitter, Input, Output } from '@angular/core';

export class BaseInputComponent {

  @Input() label = '';
  @Input() placeholder = '';
  @Input() description = '';
  @Input() descriptionOnTop = false;
  @Input() size: 'normal' | 'sm' | 'xs' | 'xxs' =  'normal';
  @Input() required = false;
  @Input() errors: {[key: string]: boolean} | null;
  @Input() messages: {[key: string]: string} = {};

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInputChange = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInputFocus = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInputBlur = new EventEmitter();

  getErrorByKey(key): string {
    if (this.messages.hasOwnProperty(key)) {
      return this.messages[key];
    }

    if (this.errors.hasOwnProperty(key) && typeof this.errors[key] === 'string') {
      // @ts-ignore
      return this.errors[key];
    }

    return 'Something is wrong with this field';
  }

  focused() {
    this.onInputFocus.emit(true);
  }

  blured() {
    this.onInputBlur.emit(true);
  }
}
