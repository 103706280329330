import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { DocumentResponseInterface } from '../model/response/document-response.interface';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { LanguageStateService } from '../../../services/language-state.service';
import { CompleteRegistrationRequest } from '../model/request/complete-registration-request';

@Injectable()
export class RegistrationApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${this.langStorage.getLang()}`
      : 'https://example.com';
  }

  public register(request) {
    const formData = new FormData();
    Object.keys(request).forEach(key => {
      if (key === 'profileImage' && request[key] && request[key].length) {
        formData.append(key, request[key][0]);
        return;
      }
      if (key === 'agreement') {
        formData.append(key, request[key] ? '1' : '0');
        return;
      }
      if (request[key]) {
        formData.append(key, request[key]);
      }
    });

    return this.http.post<any>(
      this.baseUrl + `/api/cf/registration`,
      formData
    );
  }

  public getContract(id) {
    return this.http.get<any>(
      this.baseUrl + `/api/cf/personal-area/registration/${id}/contract`,
      {headers: this.getAuthHeaders()}
    );
  }

  public signContract(id, request: {signatureDataUrl: string}) {
    return this.http.post<any>(
      this.baseUrl + `/api/cf/personal-area/contract-data/${id}/sign-contract`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public signDocument(id, request: {signatureDataUrl: string}) {
    return this.http.post<any>(
      this.baseUrl + `/api/cf/personal-area/documents/${id}/sign`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public getDocumentByType(id, type) {
    return this.http.get<DocumentResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/registration/${id}/documents`,
      {headers: this.getAuthHeaders(), params: {type}}
    );
  }

  public uploadDocument(formData) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/registration/document`,
      formData,
      {headers: this.getAuthHeaders()}
    );
  }

  public updateUploadDocument(formData, id) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/document/${id}`,
      formData,
      {headers: this.getAuthHeaders()}
    );
  }

  public completeRegistration(request: CompleteRegistrationRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/complete-registration`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public saveAgreement() {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/registration/agreement`,
      {},
      {headers: this.getAuthHeaders()}
    );
  }
}
