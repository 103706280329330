<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-secondry">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal($event)"><span aria-hidden="true">&times;</span></button>
      <h4 class="modal-title text-white" id="myModalLabel"><i class="fa fa-info-circle"></i> {{ title }}</h4>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <button type="button" class="site-button btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal" (click)="close.emit($event)">{{ 'Close' | translate }}</button>
    </div>
  </div>
</div>
