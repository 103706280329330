import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { RegistrationOwnersResponse } from '../model/response/registration-owners-response';
import { RegistrationOwnerEnumResponse } from '../model/response/registration-owner-enum-response';
import { RegistrationOwnerResponse } from '../model/response/registration-owner.response';
import { RegistrationOwnerRequest } from '../model/request/registration-owner-request';
import { OrderByInterface } from '../model/order-by.interface';

@Injectable()
export class AdminPaidAdvancesApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(page, limit, filter, orderBy: OrderByInterface[] = null) {
    return this.http.get<RegistrationOwnersResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/paid-advances',
        page,
        limit,
        orderBy || [{id: 'ASC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/paid-advances/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/paid-advances',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/paid-advances/' + id,
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
