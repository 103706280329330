import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: '[app-info-modal]',
  templateUrl: './info-modal.component.html'
})
export class InfoModalComponent implements OnInit {
  @Input() title = '';
  @Output() close = new EventEmitter();
  @Input() modal;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.renderer.addClass(this.element.nativeElement, 'modal');
    this.renderer.addClass(this.element.nativeElement, 'fade');
  }

  closeModal(e) {
    this.close.emit(e);
    this.modal.dismiss();
  }
}
