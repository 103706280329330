import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { LoginByPhone } from '../model/request/login-by-phone';

@Injectable()
export class PhoneVerifyApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public send(email) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/registration/send-verification-code`,
      {email}
    );
  }

  public sendLoginCode(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/registration/send-login-code`,
      request
    );
  }

  public loginByCode(request: LoginByPhone) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/phone-login`,
      request
    );
  }

  public verify(code, email) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/registration/verify-code`,
      {code, email}
    );
  }
}
