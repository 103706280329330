import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { PageResponseInterface } from '../model/response/page-response.interface';

@Injectable()
export class PageApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchPage(id, lang) {
    return this.http.get<PageResponseInterface>(this.baseUrl + `/${lang}` + '/api/pages/' + id);
  }
}
