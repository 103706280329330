import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DocumentListResponse } from '../model/response/document-list.response';
import { DocumentResponse } from '../model/response/document.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import {TokenService} from '../../../services/token.service';

@Injectable()
export class DocumentApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchDocs(filter, page, limit) {
    return this.http.get<DocumentListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/personal-area/documents',
        page,
        limit,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDoc(id) {
    return this.http.get<DocumentResponse>(
      this.baseUrl + '/api/cf/personal-area/document/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public uploadAttachment(formData) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/personal-area/attachment-upload`,
      formData,
      {headers: this.getAuthHeaders()}
    );
  }

  public getDownloadUrl(id) {
    const token = TokenService.getInstance().get();
    return this.baseUrl + '/cf/documents/' + id + '/download?access_token=' + token;
  }
}
