import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';

@Injectable()
export class AdminPointWalletApiService extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  // public fetchDetail(regId) {
  //   return this.http.get<any>(
  //     this.baseUrl + '/api/cf/administration/registration/{id}/buying-society'.replace('{id}', regId),
  //     {headers: this.getAuthHeaders()}
  //   );
  // }

  public create(request: {registrationId: number, amount?: number}) {
    return this.http.post<any>(
      this.baseUrl + '/api/pw/admin/v1/point-wallet',
      request,
      {headers: this.getAuthHeaders()}
    );
  }
  public createTransaction(request: {userId: number, amount?: number, type: string, description?: string}) {
    return this.http.post<any>(
      this.baseUrl + '/api/pw/admin/v1/points-transaction',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public remove(id) {
    return this.http.delete<any>(
      this.baseUrl + '/api/pw/admin/v1/point-wallet/' + id,
      {headers: this.getAuthHeaders()}
    );
  }
}
