import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractApiController } from './abstract-api-controller';
import { Configuration } from '../configuration';

@Injectable()
export class AdminRegistrationApproveApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public approveTaxi(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration-taxi/' + id + '/approve',
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public approveCourier(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration-courier/' + id + '/approve',
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public approveRegistration(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration/' + id + '/approve',
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public declineRegistration(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration/' + id + '/decline',
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public reopenRegistration(id) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/registration/' + id + '/reopen',
      {},
      {headers: this.getAuthHeaders()}
    );
  }
}
