import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { SalaryListResponse } from '../model/response/salary-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { SalaryDetailResponse } from '../model/response/salary-detail.response';

@Injectable()
export class AdminMonthlySalariesApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchSalaries(page, limit, filter = null) {
    return this.http.get<SalaryListResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/monthly-salaries',
        page,
        limit,
        [{month: 'DESC'}, {employee: 'ASC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<SalaryDetailResponse>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public save(request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/monthly-salaries'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public update(id, request) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/' + id
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public delete(id) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/' + id
      ,{headers: this.getAuthHeaders()}
    );
  }

  public getSalaryPreview(request: {registrationId: number, month: number, year: number}) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/preview'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public approveSalaries(request: {month: number, year: number}) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/approve'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public generate(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/generate'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public generateReport(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/generate-for-accounting'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public generateReportShort(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/generate-for-accounting-short'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public getGenerationState(request) {
    return this.http.post<any>(
      this.baseUrl + '/api/cf/administration/monthly-salaries/generation-state'
      , request
      ,{headers: this.getAuthHeaders()}
    );
  }

  public generateUserReport(id) {
    return this.http.get<any>(
      this.baseUrl + `/api/cf/administration/monthly-salaries/${id}/generate-report`
      ,{headers: this.getAuthHeaders()}
    );
  }
}
