import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AccessCountResponse } from '../model/response/access-count.response';

@Injectable()
export class AdminAccessApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchAccesses(filter) {
    return this.http.get<AccessCountResponse>(
      this.prepareListRequest(
        this.baseUrl + '/api/cf/administration/access-count',
        undefined,
        undefined,
        [{id: 'DESC'}],
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }
}
