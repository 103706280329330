import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../_base/base-Input.component';
import { SelectItemInterface } from '../../models/select-item.interface';

@Component({
  selector: '[form-select]',
  templateUrl: './select.component.html',
  styleUrls: [
    './select.component.scss',
    '../../styles/label.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends BaseInputComponent implements OnInit {

  @HostBinding('attr.id') externalId = '';

  @Input() options: SelectItemInterface[] = [];
  // tslint:disable-next-line:no-input-rename
  @Input('disabled') _DISABLED = false;
  @Input() tooltip = '';
  @Input() searchable = true;
  @Input() clearable = false;

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  _ID = '';

  private defaultMessages = {
    required: 'Field is required',
    minlength: 'Field does not catch minimal length',
    maxlength: 'Field is over max length',
    pattern: 'Field does not match the validation pattern',
  };

  _VALUE = null;

  @Input('value')
  set value(val) {
    this._VALUE = val;
  }

  get value() {
    return this._VALUE;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.messages = Object.assign(this.defaultMessages, this.messages);
    this.renderer.addClass(this.elementRef.nativeElement, 'form-group');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._DISABLED = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj || null;
  }

  selected(value: any) {
    this.value = value || null;
    this.onInputChange.emit(this.value);
    this.onChange(this.value);
    this.onTouched();
  }

  doChange($event) {
    this.onInputChange.emit($event.target.value);
    this.value = $event.target.value;
  }

}
