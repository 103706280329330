import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { RegistrationDetailResponse } from '../model/response/registration-detail.response';
import { RegistrationListResponse } from '../model/response/registration-list.response';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { LanguageStateService } from '../../../services/language-state.service';
import { ContractDataResponse } from '../model/response/contract-data-response';

@Injectable()
export class AdminContractApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public generateContract(contractDataId) {
    const lang = this.langStorage.getLang();
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/${lang}/api/cf/administration/registration-contract/${contractDataId}/generate`,
      {},
      {headers: this.getAuthHeaders()}
    );
  }

  public fetchDetail(id) {
    return this.http.get<any>(
      this.baseUrl + '/api/cf/administration/contract/' + id,
      {headers: this.getAuthHeaders()}
    );
  }

  public requestNewContract(id, request) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/administration/registration/' + id + '/new-contract',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public generateDocument(id, request: {documentType: string, requireSignature: boolean, signatureDate?: string}) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/contract-data/${id}/generate-document`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }
}
