import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import { Profile } from '../modules/api-module/model/profile';
import { AuthApi } from '../modules/api-module/api/auth.api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class AuthService {
  public static userProfile: Profile;
  public user = new BehaviorSubject<Profile>(null);

  constructor(
    private authApi: AuthApi
  ) {
  }

  public setProfile(profile: Profile) {
    AuthService.userProfile = profile;
    this.user.next(profile);
  }

  public redirectToLogin() {
    TokenService.getInstance().clearToken();
    location.href = 'personal-area/login';
  }

  public loadProfile()
  {
    const token = TokenService.getInstance().get()
    if (token) {
      this.authApi.getProfile(token).subscribe(r => {
        if (r.code === 200) {
          this.setProfile(r.content);
        }
      }, e => {
        if (e instanceof HttpErrorResponse && e.status === 401) {
          TokenService.getInstance().clearToken();
        }
      });
    }
  }
}
