import { Injectable, Optional } from '@angular/core';
import { AbstractApiController } from './abstract-api-controller';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { ProgramFeeConfigListResponseInterface } from '../model/response/program-fee-config-list-response.interface';
import { ProgramFeesConfigRequest } from '../model/request/program-fees-config.request';
import { ProgramRecruitmentBonusConfigRequest } from '../model/request/program-recruitment-bonus-config.request';
import {
  ProgramRecruitmentBonusConfigListResponseInterface
} from '../model/response/program-recruitment-bonus-config-list-response.interface';
import { OrderByInterface } from '../model/order-by.interface';

@Injectable()
export class AdminProgramRecruitmentBonusApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    @Optional() config?: Configuration
  ) {
    super();
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl : 'https://example.com';
  }

  public fetchList(
    filter,
    page,
    limit,
    orderBy: OrderByInterface[] = [{id: 'DESC'}]
  ) {
    return this.http.get<ProgramRecruitmentBonusConfigListResponseInterface>(
      this.prepareListRequest(
        this.baseUrl + `/api/cf/administration/program-recruitment-bonus-configs`,
        page,
        limit,
        orderBy,
        filter
      ),
      {headers: this.getAuthHeaders()}
    );
  }

  public saveNew(request: ProgramRecruitmentBonusConfigRequest) {
    return this.http.post<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/program-recruitment-bonus-configs`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public update(id: number, request: ProgramRecruitmentBonusConfigRequest) {
    return this.http.put<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/program-recruitment-bonus-configs/${id}`,
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public delete(id: number) {
    return this.http.delete<DefaultResponseInterface>(
      this.baseUrl + `/api/cf/administration/program-recruitment-bonus-configs/${id}`,
      {headers: this.getAuthHeaders()}
    );
  }
}
