import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[bootstrapButton]'
})
export class BootstrapButtonDirective implements OnInit, OnChanges {

  @Input() color: 'primary' | 'secondary' | 'danger' | 'success' | 'info' | 'warning' | 'link' = 'secondary';
  @Input() size?: 'lg' | 'normal' | 'small' | 'sm' | 'xs' | 'xxs' = null;
  @Input() processing = false;
  @Input() iconOnly = false;
  @Input() iconPlacement: 'right' | 'left';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {

  }

  ngOnInit(): void {
    this.renderer.addClass(
      this.el.nativeElement,
      'btn'
    );

    if (this.color) {
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-' + this.color
      );
    }

    if (this.iconOnly) {
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-icon'
      );
    }

    if (this.iconPlacement) {
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-icon-' + this.iconPlacement
      );
    }

    this.renderer.addClass(
      this.el.nativeElement,
      `btn-${this.size}`
    );

    if (this.processing) {
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-processing'
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processing && changes.processing.currentValue) {
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-processing'
      );
    } else if(changes.processing && !changes.processing.currentValue) {
      this.renderer.removeClass(
        this.el.nativeElement,
        'btn-processing'
      );
    }

    if (changes.color && !changes.color.firstChange) {
      this.renderer.removeClass(
        this.el.nativeElement,
        'btn-' + changes.color.previousValue
      );
      this.renderer.addClass(
        this.el.nativeElement,
        'btn-' + changes.color.currentValue
      );
    }
  }
}
