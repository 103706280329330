import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../_base/base-Input.component';

@Component({
  selector: '[verification-code-input]',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VerificationCodeComponent),
      multi: true
    }
  ]
})
export class VerificationCodeComponent extends BaseInputComponent implements ControlValueAccessor, OnInit {
  @HostBinding('attr.id') externalId = '';

  @ViewChild('input', { static: true }) input: ElementRef;

  @Input() type = 'text';
  // tslint:disable-next-line:no-input-rename
  @Input('disabled') _DISABLED = false;
  @Input() tooltip = '';
  @Input() length = 6;

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  _ID = '';

  private defaultMessages = {
    required: 'Field is required',
    minlength: 'Field does not catch minimal length',
    maxlength: 'Field is over max length',
    pattern: 'Field does not match the validation pattern',
  };

  _VALUE = '';

  @Input('value')
  set value(val) {
    this._VALUE = val;
  }

  get value() {
    return this._VALUE;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.messages = Object.assign(this.defaultMessages, this.messages);
    this.renderer.addClass(this.elementRef.nativeElement, 'form-group');
    for (let i = 0; i <= this.length; i++) {
      this.placeholder += '_';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._DISABLED = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj === null) {
      this.input.nativeElement.value = '';
    }
    this.value = (obj !== null && obj !== undefined) ? obj : '';
  }

  doChange($event) {
    this.onInputChange.emit($event.target.value);
    this.onChange($event.target.value);
    this.onTouched();
  }

  focus() {
    this.input.nativeElement.focus();
  }
}
