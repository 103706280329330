<label [for]="_ID" *ngIf="label" [ngClass]="{required: required}">{{ label }}</label>

<div class="help-block" *ngIf="description && descriptionOnTop">
  <span [innerHtml]="description" class="text-info"></span>
</div>

<input
  [type]="type"
  [className]="'form-control form-control-' + size"
  [value]="value"
  [id]="_ID"
  [disabled]="_DISABLED"
  [placeholder]="placeholder"
  [required]="required"
  [step]="type === 'number' ? decimalFormat : undefined"
  [attr.inputmode]="inputMode"
  (input)="doChange($event)"
  (blur)="blur.emit($event)"
  #input
>
<div class="help-block" *ngIf="errors">
  <span class="text-danger" *ngFor="let error of errors | keyvalue">{{ getErrorByKey(error.key) }}</span>
</div>
<div class="help-block" *ngIf="description && !descriptionOnTop">
  <span class="text-info" [innerHtml]="description"></span>
</div>
<div class="input-tooltip" *ngIf="tooltip">
  {{ tooltip }}
  <span class="input-tooltip-pointer"></span>
</div>
