import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Header1Component } from './components/header/header1/header1.component';
import { Header2Component } from './components/header/header2/header2.component';
import { Header3Component } from './components/header/header3/header3.component';
import { Footer1Component } from './components/footer/footer1/footer1.component';
import { BannerComponent } from './components/banner/banner.component';
import { PostContentComponent } from './components/post-content/post-content.component';
import { PipesModule } from '../pipes/pipes.module';
import { BrunoFormsModule } from '../forms/bruno-forms.module';
import { SectionElementsModule } from '../section-elements/section-elements.module';

@NgModule({
  declarations: [
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    BannerComponent,
    PostContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    TranslateModule,
    PipesModule,
    BrunoFormsModule,
    ReactiveFormsModule,
    FormsModule,
    SectionElementsModule
  ],
  exports: [
    RouterModule,
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    BannerComponent,
    PostContentComponent
  ],
  providers: []
})
export class PageSectionsModule {

}
