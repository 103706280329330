import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { padNumber } from '../../helpers/pad-number';

@Injectable()
export class TranslateDatePicker extends NgbDatepickerI18n {

  private daysShort = [];
  private monthsShort = [];
  private months = [];

  constructor(
    private translate: TranslateService
  ) {
    super();
    this.loadTranslations();
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}-${padNumber(date.month, 1)}-${padNumber(date.day, 1)}`;
  }

  getMonthFullName(month: number, year?: number): string {
    return this.months[month-1];
  }

  getMonthShortName(month: number, year?: number): string {
    return this.monthsShort[month-1];
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.daysShort[weekday-1];
  }

  loadTranslations() {
    this.translate.get('DAYS_SHORT').subscribe(days => (this.daysShort = days));
    this.translate.get('MONTHS_SHORT').subscribe(months => (this.monthsShort = months));
    this.translate.get('MONTHS').subscribe(months => {
      this.months = Object.keys(months).map(key => months[key]);
    });
  }

}
