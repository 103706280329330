import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

export function validatePhoneNumber(c: FormControl) {

  if (!c.value) { return null; }

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    phoneUtil.parseAndKeepRawInput(c.value, '');

    return null;
  } catch (e) {
    return {
      phoneNumber: {
        valid: false
      }
    };
  }
}
