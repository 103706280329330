export class StorageService {
    static get(item: string) {
        return localStorage.getItem(item);
    }

    static set(item: string, value: string) {
        localStorage.setItem(item, value);
    }

    static remove(item: string) {
        localStorage.removeItem(item);
    }
}
