import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateFileTypes(val: string | string[]): ValidatorFn {
  let extensions: string[] = [];

  if (typeof val === 'string') {
    const tmpExt = val.split(',');
    tmpExt.forEach(ext => extensions.push(ext.trim()));
  } else {
    extensions = val;
  }

  return (c: AbstractControl): ValidationErrors | null => {

    if (!c.value) { return null; }
    if (!c.value.length) { return null; }

    let valid = true;

    for (const file of c.value) {
      const ext = file.name.split('.').slice(-1)[0];
      if (-1 === extensions.findIndex(item => (item === ext.toLowerCase()))) {
        valid = false;
        break;
      }
    }

    return valid ? null : {
      validateFileTypes: {
        valid: false
      }
    };
  };

}
