import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calculator } from '../../modules/api-module/model/calculator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '../../helpers/form-error-helper';
import { CaptchaApi } from '../../modules/api-module/api/captcha.api';
import { RegistrationApi } from '../../modules/api-module/api/registration.api';
import { RegistrationRequest } from '../../modules/api-module/model/request/registration-request';
import { HttpErrorResponse } from '@angular/common/http';
import { validatePhoneNumber } from '../../modules/forms/validators/phone-number.validator';
import { StorageService } from '../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TempRegistrationApi } from '../../modules/api-module/api/temp-registration.api';

@Component({
  selector: 'app-pre-registration-page',
  templateUrl: './pre-registration.component.html',
  styleUrls: [
    './pre-registration.component.scss'
  ]
})
export class PreRegistrationComponent implements OnInit, OnDestroy {

  public banner = {
    image: "assets/images/banner/banner-reg.jpg",
    title: "REG.banner_title",
    page: "REG.banner_page"
  }

  public name: string;
  public processing = false;

  public boltStandingOptions = [
    {label: 'Pracant', value: 'WORKER'},
    {label: 'Makač', value: 'WORKAHOLIC'},
    {label: 'Brigádnik', value: 'PART_TIMER'},
  ];
  public fee = '0.00';
  public calculator: Calculator;
  formErrors: FormErrorHelper;
  errorMessages = {
    required: 'Toto pole je povinné',
    minlength: 'Pole nespĺňa minmálnu dĺžku',
    maxlength: 'Pole prekročilo maximálnu dĺžku',
    pattern: 'Pole nespĺňa validačné pravidlá',
    email: 'Vlož validný email'
  };
  emailErrors = {...this.errorMessages, serverError: 'Email už existuje'};
  captchaErrors = {...this.errorMessages, serverError: 'Platnosť overenia vypršala', required: 'Potvrď, že si človek'};
  phoneErrors = {...this.errorMessages, phoneNumber: 'Vlož platné telefónne číslo'};
  agreementRouteLink = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private captchaAPI: CaptchaApi,
    private registrationAPI: RegistrationApi,
    private tempRegistrationAPI: TempRegistrationApi,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      if (p['prereg']) {
        StorageService.set('preregToken', p['prereg']);
        this.tempRegistrationAPI.getTempRegistration(p['prereg']).subscribe(r => {
          this.name = r.content.firstName + ' ' + r.content.lastName;
        });
        return;
      }
      else if (StorageService.get('preregToken')) {
        const token = StorageService.get('preregToken');
        this.tempRegistrationAPI.getTempRegistration(token).subscribe(r => {
          this.name = r.content.firstName + ' ' + r.content.lastName;
        });
        return;
      }
    });
  }

  ngOnDestroy(): void {
  }

  continue() {
    const token = StorageService.get('preregToken');

    this.router.navigate(['/registration'], {queryParams: {prereg: token}});
  }

  noMe() {
    StorageService.remove('preregToken');

    this.router.navigate(['/registration']);
  }
}
