import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SectionElementsModule } from './modules/section-elements/section-elements.module';
import { PageSectionsModule } from './modules/page-sections/page-sections.module';
import { JoinUsComponent } from './pages/join-us/join-us.component';
import { PostImageComponent } from './pages/article/post-image/post-image.component';
import { BrunoFormsModule } from './modules/forms/bruno-forms.module';
import { CalculatorTaxiComponent } from './pages/calculator-taxi/calculator-taxi.component';
import { CalculatorCourierComponent } from './pages/calculator-courier/calculator-courier.component';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Configuration } from './modules/api-module/configuration';
import { environment } from '../environments/environment';
import { ApiModule } from './modules/api-module/api.module';
import { RegistrationComponent } from './pages/registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { XdebugInterceptor } from './services/xdebug.interceptor';
import { AuthComponent } from './pages/auth/auth.component';
import { RegistrationStateComponent } from './pages/personal-area/registration-state/registration-state.component';
import { LogoutComponent } from './pages/logout/logout.component';
import {
  DataItemRowComponent
} from './pages/personal-area/registration-state/components/data-item-row/data-item-row.component';
import { PhoneVerificationComponent } from './pages/phone-verification/phone-verification.component';
import {
  TaxiRegistrationComponent
} from './pages/personal-area/registration-state/components/taxi-registration/taxi-registration.component';
import {
  CourierRegistrationComponent
} from './pages/personal-area/registration-state/components/courier-registration/courier-registration.component';
import { MessagingModule } from './modules/messaging/messaging.module';
import { ErrorInterceptor } from './services/error.interceptor';
import { VerifyNumberFormComponent } from './components/verify-number-form/verify-number-form.component';
import { SpinnersModule } from './modules/spinners/spinners.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ManualPreviewComponent } from './pages/manual-preview/manual-preview.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DocumentFilterService } from './services/filters/document-filter.service';
import { BzDataTableModule } from './modules/data-table/bz-data-table.module';
import { FilterChipsModule } from './components/filter-chips/filter-chips.module';
import { RouterModule } from '@angular/router';
import { VerifyNumberModule } from './components/verify-number-form/verify-number.module';
import { ContractChangeService } from './services/contract-change.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BackendTranslateLoader } from './services/backend-translate-loader';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageStateService } from './services/language-state.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { TranslateDatePicker } from './services/ngb-date/translate-date-picker';
import { DateParserFormatter } from './services/ngb-date/date-parser-formatter';
import { AccessLoggingService } from './services/access-logging.service';
import { NgbConfirmModalComponent } from './components/modals/ngb-confirm-modal/ngb-confirm-modal.component';
import { CourierEarningsFilterService } from './services/filters/courier-earnings-filter.service';
import { TaxiEarningsFilterService } from './services/filters/taxi-earnings-filter.service';
import { AdminTaxiEarningsFilterService } from './services/filters/admin-taxi-earnings-filter.service';
import { AdminCourierEarningsFilterService } from './services/filters/admin-courier-earnings-filter.service';
import { LeviesService } from './services/levies-service';
import { PreRegistrationComponent } from './pages/pre-registration/pre-registration.component';
import { DefaultFilterService } from './services/filters/default-filter.service';
import { NgbInfoModalComponent } from './components/modals/ngb-info-modal/ngb-info-modal.component';
import { PlaceOfWorkService } from './services/place-of-work.service';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';

export function createApiConfiguration() {
  return new Configuration({apiBaseUrl: environment.apiUrl});
}

export function createTranslationLoader(http: HttpClient) {
  return new BackendTranslateLoader(http, environment.apiUrl, 'api/translations');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    JoinUsComponent,
    PostImageComponent,
    CalculatorTaxiComponent,
    CalculatorCourierComponent,
    RegistrationComponent,
    LoginComponent,
    AuthComponent,
    LogoutComponent,
    PhoneVerificationComponent,
    ManualPreviewComponent,
    ContactUsComponent,
    NgbConfirmModalComponent,
    PreRegistrationComponent,
    NgbInfoModalComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApiModule.forRoot(createApiConfiguration),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslationLoader,
        deps: [HttpClient]
      }
    }),
    SectionElementsModule,
    PageSectionsModule,
    ReactiveFormsModule,
    FormsModule,
    BrunoFormsModule,
    NgbModule,
    MessagingModule,
    SpinnersModule,
    PdfViewerModule,
    BzDataTableModule,
    FilterChipsModule,
    VerifyNumberModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: XdebugInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDatepickerI18n, useClass: TranslateDatePicker },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    DocumentFilterService,
    ContractChangeService,
    LanguageStateService,
    ErrorHandlerService,
    AccessLoggingService,
    CourierEarningsFilterService,
    TaxiEarningsFilterService,
    AdminTaxiEarningsFilterService,
    AdminCourierEarningsFilterService,
    LeviesService,
    DefaultFilterService,
    PlaceOfWorkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
