import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FilterChipsComponent
} from './filter-chips-component/filter-chips.component';

@NgModule({
  declarations: [
    FilterChipsComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FilterChipsComponent
  ],
  providers: []
})
export class FilterChipsModule {
}
