export class ConfigurationService {
  private static configs = {};

  public static set(configs) {
    ConfigurationService.configs = configs;
  }

  public static get(key = null) {
    if (key) {
      return ConfigurationService.configs[key];
    }

    return ConfigurationService.configs;
  }
}
