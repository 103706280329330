import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../configuration';
import { AbstractApiController } from './abstract-api-controller';
import { DefaultResponseInterface } from '../model/response/default-response.interface';
import { PatchRequest } from '../model/request/patch-request';
import { Patch } from '../model/patch';
import {LanguageStateService} from '../../../services/language-state.service';

@Injectable()
export class PatchApi extends AbstractApiController {

  constructor(
    private http: HttpClient,
    private langStorage: LanguageStateService,
    @Optional() config?: Configuration
  ) {
    super();
    const lang = langStorage.getLang() || 'sk';
    this.baseUrl = (config && config.apiBaseUrl)
      ? config.apiBaseUrl + `/${lang}` : 'https://example.com';

    this.langStorage.languageState.subscribe(l => {
      this.baseUrl = (config && config.apiBaseUrl)
        ? config.apiBaseUrl + `/${l}` : 'https://example.com';
    });
  }

  public patch(request: PatchRequest) {
    return this.http.patch<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/personal-area/patch',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public patchEmail(request: PatchRequest) {
    return this.http.patch<DefaultResponseInterface>(
      this.baseUrl + '/api/cf/personal-area/email-patch',
      request,
      {headers: this.getAuthHeaders()}
    );
  }

  public patchValue(object, id, property, value) {
    const patch = {patch: [new Patch('replace', `/${object}/${id}/${property}`, value)]};

    return this.patch(patch);
  }

  public patchValues(patches: {object, id, property, value}[]) {
    const patch = {patch: []};
    patch.patch = patches.map(p => {
      return new Patch('replace', `/${p.object}/${p.id}/${p.property}`, p.value);
    });

    return this.patch(patch);
  }
}
