export interface ConfigurationParameters {
  apiBaseUrl?: string;
}

export class Configuration {
  public apiBaseUrl?: string;

  constructor(configurationParameters: ConfigurationParameters = {}) {
    this.apiBaseUrl = configurationParameters.apiBaseUrl;
  }

}
